import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Text,
  Checkbox,
  VStack
} from '@chakra-ui/react';

const AccessListModal = ({ isOpen, onClose, allClientsAccess, allDocsAccess, onRevoke, selectedEmployeeId }) => {
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedDocs, setSelectedDocs] = useState([]);

  const handleCheckboxChange = (id, type) => {
    if (type === 'client') {
      setSelectedClients(prevSelected =>
        prevSelected.includes(id)
          ? prevSelected.filter(item => item !== id)
          : [...prevSelected, id]
      );
    } else if (type === 'document') {
      setSelectedDocs(prevSelected =>
        prevSelected.includes(id)
          ? prevSelected.filter(item => item !== id)
          : [...prevSelected, id]
      );
    }
  };

  const handleRevoke = () => {
    const payload = {
      "employeeID": selectedEmployeeId,
      "accessLevelIDs": [...selectedClients, ...selectedDocs]
    }
    onRevoke(payload);
    setSelectedClients([]);
    setSelectedDocs([]);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Access List</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {allClientsAccess.length>0 && <Box>
            <Text fontSize="xl" fontWeight="bold" mb={4}>
              Clients Access
            </Text>
            <VStack
              align="start"
              spacing={2}
              maxH="200px"
              overflowY="auto"
              border="1px solid #ccc"
              borderRadius="md"
              p={2}
            >
              {allClientsAccess.map(client => (
                <Checkbox
                  key={client.id}
                  isChecked={selectedClients.includes(client.id)}
                  onChange={() => handleCheckboxChange(client.id, 'client')}
                >
                  {client.itemName}
                </Checkbox>
              ))}
            </VStack>
          </Box>}
          {allDocsAccess.length>0 && <Box mt={8}>
            <Text fontSize="xl" fontWeight="bold" mb={4}>
              Documents Access
            </Text>
            <VStack
              align="start"
              spacing={2}
              maxH="200px"
              overflowY="auto"
              border="1px solid #ccc"
              borderRadius="md"
              p={2}
            >
              {allDocsAccess.map(doc => (
                <Checkbox
                  key={doc.id}
                  isChecked={selectedDocs.includes(doc.id)}
                  onChange={() => handleCheckboxChange(doc.id, 'document')}
                >
                  {doc.itemName}
                </Checkbox>
              ))}
            </VStack>
          </Box>}
        </ModalBody>
        <ModalFooter>
          <Button className="btn-save" mr={3} onClick={handleRevoke}>
            Revoke
          </Button>
          <Button className="btn-close" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AccessListModal;
