import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useTable, useSortBy, useGlobalFilter } from 'react-table';
import { useLocation } from 'react-router-dom';
import { FcFolder } from '@react-icons/all-files/fc/FcFolder';
import { FiFileText } from '@react-icons/all-files/fi/FiFileText';
import {FiSearch} from '@react-icons/all-files/fi/FiSearch';
import { FiMoreVertical } from '@react-icons/all-files/fi/FiMoreVertical';
import {FaRegCircle  } from '@react-icons/all-files/fa/FaRegCircle';
import {IoIosCheckmarkCircle } from '@react-icons/all-files/io/IoIosCheckmarkCircle';
import {RiDeleteBinFill} from '@react-icons/all-files/ri/RiDeleteBinFill';
import {FaCloudDownloadAlt} from '@react-icons/all-files/fa/FaCloudDownloadAlt';
import {FiEdit3} from '@react-icons/all-files/fi/FiEdit3';
import MoreActions from './MoreActions';
import { FaCloudUploadAlt } from '@react-icons/all-files/fa/FaCloudUploadAlt'
import { FaPlusCircle } from "@react-icons/all-files/fa/FaPlusCircle.js";
import { IoMdMove } from "@react-icons/all-files/io/IoMdMove";
import DocumentUploadModal from './DocumentUploadModal';
import * as Util from '../Utils/Util';
import ApiService from '../Services/ApiService';
import Loader from './Loader';
import FolderTrailComponent from './FolderTrail';
import DocumentOperationModal from './DocumentOperationModal';
import DocumentMapperModal from './DocumentMapperModal';
import DocumentFileEditModal from './DocumentFileEditModal';
import DocumentFileDeletionModal from './DocumentFileDeletionModal';
import DocumentFolderDeletionModal from './DocumentFolderDeletionModal';
import DocumentRenderModal from './DocumentRenderModal';
import { useData } from './DataContext';
import { uploadFile, fileIcon, downloadFile, convertFileSize, triggerClientDownload } from '../Utils/FileUtil';
import { useDropzone } from 'react-dropzone';
import {SiOpenaccess } from '@react-icons/all-files/si/SiOpenaccess';
import GiveAccessModal from './GiveAccessModal';
import { useToast } from './ToastContext';
import Toast from './ToastComponent';

function globalFilter(rows, ids, query) {
    const lowercasedQuery = query.toLowerCase();

    return rows.filter(row => {
        return Object.values(row.original).some(value => 
            value !== null && value !== undefined && value.toString().toLowerCase().includes(lowercasedQuery)
        );
    });
}

const Documents = () => {
    let location = useLocation();
    const [isModalVisible, setModalVisible] = useState(false);
    const [isRenameModalVisible, setRenameModalVisible] = useState(false);
    const [isDeleteFilesModalVisible, setDeleteFilesModalVisible] = useState(false);
    const [isDeleteFolderModalVisible, setDeleteFolderModalVisible] = useState(false);
    const [isNewFolderModalVisible, setNewFolderModalVisible] = useState(false);
    const [isDocumentMapperModalVisible, setDocumentMapperModalVisible] = useState(false);
    const [isFileMoverModalVisible, setIsFileMoverModalVisible] = useState(false);
    const [isFileEditModalVisible, setIsFileEditModalVisible] = useState(false);
    const [isDocumentRenderModalVisible, setIsDocumentRenderModalVisible] = useState(false);
    const [isFetchingFiles, setFetchingFiles] = useState(false);
    const [currentFolderID, setCurrentFolderID] = useState(-1);
    const [documents, setDocuments] = useState({});
    const [orderDocuments, setOrderDocuments] = useState({});
    const [orders, setOrders] = useState([]);
    const [folderTrail, setFolderTrail] = useState([]);
    const [data, setData] = useState([]);
    const [checkedRows, setCheckedRows] = useState([]);
    const [documentIds, setDocumentIds] = useState([]);
    const [topLevelFolderID, setTopLevelFolderID] = useState(-1);
    const currentClient = location.state.currentClient;
    const { orderList } = useData();
    const [dropParentFolderID, setDropParentFolderID] = useState(null);
    const [droppableFiles, setDroppableFiles] = useState([]);
    const [moveableFiles, setMoveableFiles] = useState([]);
    const [isGiveAccessModalOpen, setIsGiveAccessModalOpen] = useState(false);
    const { toast, showToast } = useToast();
    const [documentToRender, setDocumentToRender] = useState(null);
    const [softDownloadedFiles, setSoftDownloadedFiles] = useState([]);

    const menuItems = [
        { label: 'Rename', onClick: () => handleOpenRenameModal() },
        { label: 'Delete', onClick: () => console.log('Delete clicked') },
    ];

    //DO NOT TOUCH
    const customSortFunction = (rowA, rowB, columnId, desc) => {
        if(columnId === 'name'){
            if(rowA.original.type === 'file' && rowB.original.type === 'folder'){
                return desc ? -1 : 1;
            }
            else if(rowA.original.type === 'folder' && rowB.original.type === 'file'){
                return desc ? 1 : -1;
            }
            const aValue = rowA.original.name;
            const bValue = rowB.values.name;
            return aValue.toLowerCase().localeCompare(bValue.toLowerCase());
        }
        else if(columnId === 'uploadDate'){
            if(rowA.original.type === 'folder' && rowB.original.type === 'folder'){
                return desc ? -1 : 0;
            }
            if(rowA.original.type === 'file' && rowB.original.type === 'file'){
                const aValue = rowA.original.createdAt;
                const bValue = rowB.original.createdAt;
                return aValue < bValue ? -1 : 1;
            }
        }
        else if(columnId === 'size'){
            if(rowA.original.type === 'folder' && rowB.original.type === 'folder'){
                return desc ? -1 : 0;
            }
            if(rowA.original.type === 'file' && rowB.original.type === 'file'){
                const aValue = rowA.original.fileSize;
                const bValue = rowB.original.fileSize;
                return aValue < bValue ? -1 : 1;
            }
        }
    };

    const columns = useMemo(
        () => [
            { // Checkbox column
                Header: '',
                accessor: 'select',
                disableSortBy: true,
                className: "sticky",
                width: "5%",
                maxWidth: "5%",
            },
            {
                Header: 'Name',
                accessor: 'name',
                width: "40%",
                maxWidth: "40%",
                sortType: customSortFunction,
            },
            {
                Header: 'Size',
                accessor: 'size',
                width: "5%",
                maxWidth: "5%",
                sortType: customSortFunction,
            },
            {
                Header: 'Upload Date',
                accessor: 'uploadDate',
                width: "15%",
                maxWidth: "15%",
                style:{textAlign:'center'},
                sortType: customSortFunction,
            },
            {
                Header: 'Uploaded By',
                accessor: 'uploadedBy',
                disableSortBy: true,
                width: "10%",
                maxWidth: "10%",
            },
            {
                Header: 'Notes',
                accessor: 'notes',
                disableSortBy: true,
                width: "25%",
                maxWidth: "25%",
            }
        ],
        []
    );

    const handleOpenModal = () => {
        setModalVisible(true)
    };
    const handleCloseModal = () => {
        setDropParentFolderID(null);
        setModalVisible(false);
    }
    const handleSaveTask = async(files, parentFolderID=null) => {
        setFetchingFiles(true);
        setDropParentFolderID(null);
        for(let i=0; i<files.length; i++){
            const _uploadedFile = await uploadFile(files[i], 'billfoldermap');
            const _parentFolderID = parentFolderID === null ? folderTrail[folderTrail.length - 1].folderID : parentFolderID;
            await attachFilesToFolder([_uploadedFile.id], _parentFolderID);
            const _allFolders = data.filter(item => item.type === 'folder');
            let _allFiles = data.filter(item => item.type === 'file');
            _allFiles = [_uploadedFile, ..._allFiles];
            const _finalList = _allFolders.concat(_allFiles);
            setData(_finalList);
        }
        let curr_folderID = folderTrail[folderTrail.length - 1].folderID;
        retrieveDocuments(curr_folderID, true);
        setFetchingFiles(false);
    };

    const handleOpenFileMoveModal = () => setIsFileMoverModalVisible(true);
    const handleCloseFileMoveModal = () => {
        setCheckedRows([]);
        setIsFileMoverModalVisible(false);
    }

    const handleOpenGiveAccessModal = () => {
        const docIds = checkedRows.map(obj => obj.billFolderMapID);
        setDocumentIds([...docIds]);
		setIsGiveAccessModalOpen(true);
	};

	const handleCloseGiveAccessModal = () => {
		setIsGiveAccessModalOpen(false);
        setCheckedRows([]);
        setDocumentIds([]);
	};

    const handleFileMove = async(billFolderMapIDs, folderID) =>{
        try{
            const endpoint = "advisor/folder/reattachFiles";
            const payLoad = {
                folderID:folderID,
                billFolderMapIDs: billFolderMapIDs
            }
            const response = await ApiService.post(endpoint, payLoad);
            if(response.status === 200){
                const responseBody = await response.json();
                await fetchFilesAndFolders(folderID);
                let curr_folderID = folderTrail[folderTrail.length - 1].folderID;
                retrieveDocuments(curr_folderID, true);
                handleCloseFileMoveModal();
            }
            else{
                //pop up error TODO
            }
        }
        catch (error){
            console.log(error);
        }
    }

    const handleOpenRenameModal = () =>setRenameModalVisible(true);
    const handleCloseRenameModal = () => {
        setCheckedRows([]);
        setRenameModalVisible(false);
    }
    const handleRenameTask = async(newFolderName, foldeID) => {
        try{
            const endpoint = "advisor/folder/rename";
            const payLoad = {
                newFolderName: newFolderName,
                folderID: foldeID
            }
            const response = await ApiService.post(endpoint, payLoad);
            if(response.status === 200){
                const responseBody = await response.json();
                //console.log(responseBody);
                let curr_folderID = folderTrail[folderTrail.length - 1].folderID;
                retrieveDocuments(curr_folderID, true);
            }
            else{
                //pop up error TODO
            }
        }
        catch (error){
            console.log(error);
        }
    };

    const handleOpenNewFolderModal = () => setNewFolderModalVisible(true);
    const handleCloseNewFolderModal = () => {
        setCheckedRows([]);
        setNewFolderModalVisible(false);
    }
    const handleNewFolderTask = async(newFolderName) => {
        try{
            let curr_folderID = folderTrail[folderTrail.length - 1].folderID;
            const endpoint = "advisor/folder/create";
            const payLoad = {
                folderOwner:currentClient.userId,
                folderName: newFolderName,
                folderID: curr_folderID
            }
            const response = await ApiService.post(endpoint, payLoad);
            if(response.status === 201){
                const responseBody = await response.json();
                //console.log(responseBody);
                retrieveDocuments(curr_folderID, true);
            }
            else{
                //pop up error TODO
            }
        }
        catch (error){
            console.log(error);
        }
    };

    const handleOpenDeleteFilesModal = () => {console.log(checkedRows);setDeleteFilesModalVisible(true);}
    const handleCloseDeleteFilesModal = () => {
        ClearCheckedRows();
        setDeleteFilesModalVisible(false);
    }
    const handleFileDeletionTask = async(fileIDs) =>{
        try{
            let curr_folderID = folderTrail[folderTrail.length - 1].folderID;
            const endpoint = "advisor/folder/deleteFiles";
            const payLoad = {
                folderID : curr_folderID,
                fileIDs : fileIDs
            }
            const response = await ApiService.post(endpoint, payLoad);
            if(response.status === 200){
                retrieveDocuments(curr_folderID, true);
            }
            else{
                //pop up error TODO
            }
        }
        catch (error){
            console.log(error);
        }
    }

    const handleOpenDeleteFolderModal = () => {console.log(checkedRows);setDeleteFolderModalVisible(true);}
    const handleCloseDeleteFolderModal = () => {
        ClearCheckedRows();
        setDeleteFolderModalVisible(false);
    }
    const handleFolderDeletionTask = async(folderID) =>{
        try{
            const endpoint = "advisor/folder/deleteEmptyFolder";
            const payLoad = {
                folderID : folderID,
                folderOwner : currentClient.userId
            }
            const response = await ApiService.post(endpoint, payLoad);
            if(response.status === 200){
                const responseBody = await response.json();
                console.log(responseBody);
                
                let curr_folderID = folderTrail[folderTrail.length - 1].folderID;
                retrieveDocuments(curr_folderID, true);
            }
            else{
                //pop up error TODO
            }
        }
        catch (error){
            console.log(error);
        }
    }

    const handleOpenDocumentMapperModal = () => setDocumentMapperModalVisible(true);
    const handleCloseDocumentMapperModal = () => setDocumentMapperModalVisible(false);
    const attachFilesToFolder = async(itemIDs, folderID, orderID=null) =>{
        try{
            const endpoint = "advisor/folder/attachFiles";
            const payLoad = {
                folderID:folderID,
                fileIDs: itemIDs
            }
            if(orderID !== null)
                payLoad.orderID = orderID;
            const response = await ApiService.post(endpoint, payLoad);
            if(response.status === 200){
                const responseBody = await response.json();
                //console.log(responseBody);
            }
            else{
                //pop up error TODO
            }
        }
        catch (error){
            console.log(error);
        }
    }

    const handleOpenFileEditModal = () => setIsFileEditModalVisible(true);
    const handleCloseFileEditModal = () => {
        ClearCheckedRows();
        setIsFileEditModalVisible(false);
    }
    const editFileDetails = async(newFileName, newNote, billFolderMapID) =>{
        try{
            const endpoint = "advisor/folder/editFile";
            const payLoad = {
                billFolderMapID: billFolderMapID,
                fileName:newFileName,
                note: newNote
            }
            const response = await ApiService.post(endpoint, payLoad);
            if(response.status === 200){
                const responseBody = await response.json();
                let curr_folderID = folderTrail[folderTrail.length - 1].folderID;
                retrieveDocuments(curr_folderID, true);
            }
            else{
                //pop up error TODO
            }
        }
        catch (error){
            console.log(error);
        }
    }

    const detachFilesToFolder = async(itemIDs, folderID) =>{
        try{
            const endpoint = "advisor/folder/detachFiles";
            const payLoad = {
                folderID:folderID,
                fileIDs: itemIDs
            }
            const response = await ApiService.post(endpoint, payLoad);
            if(response.status === 200){
                const responseBody = await response.json();
                //console.log(responseBody);
            }
            else{
                //pop up error TODO
            }
        }
        catch (error){
            console.log(error);
        }
    }

    const onPressDownload = async() =>{
        //all files
        //setFetchingFiles(true);
        for(let _checkedRow of checkedRows){
            if(_checkedRow.type !== 'file')
                continue;
            await downloadFile([_checkedRow.billFolderMapID], 'billFolderMapIds', _checkedRow.name);
        }
        setCheckedRows([]);
        //setFetchingFiles(false);
        
        //todo - all files inside the folder

    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setGlobalFilter,
        selectedFlatRows,
        state : { sortBy }
        } = useTable(
            { columns, data, globalFilter, initialState: {
            sortBy: [
                {
                    id: 'uploadDate',
                    desc: true
                }
            ]
            } }, useGlobalFilter, useSortBy
        );

    //first time running
    useEffect(() =>{
        const firstFetch = async() =>{
            await retrieveDocuments(-1);
            let _topLevelFolderID = Object.keys(documents)[0];
            setTopLevelFolderID(_topLevelFolderID);
            setFolderTrail([{name:Util.getFullName(currentClient.firstName, currentClient.lastName), folderID:_topLevelFolderID}]);
        }
        firstFetch();
    },[]);

    const retrieveDocuments = async(folderID, force=false) =>{
        let parentFolderID = folderID;
        if (!documents.hasOwnProperty(folderID) || force){
            parentFolderID = await fetchFilesAndFolders(folderID);
        }
        setData(documents[parentFolderID]);
    }

    const formatIncomingDocumentDetails = (docs, type) =>{
        let _updatedDocs = [];
        if(type === 'folder'){
            let _key = 1;
            for(let _doc of docs){
                let _updatedDoc = {
                    id : _doc.id,
                    type : type,
                    depth1 : _doc.depth1,
                    depth2 : _doc.depth2,
                    depth3 : _doc.depth3,
                    depth4 : _doc.depth4,
                    depth5 : _doc.depth5,
                    depth6 : _doc.depth6,
                    depth7 : _doc.depth7,
                    depth8 : _doc.depth8,
                    depth9 : _doc.depth9,
                    depth10 : _doc.depth10,
                    key : _key
                };
                for (let i = 1; i <= 10; i++){
                    let _depthStr = 'depth' + i.toString()
                    let _name = _updatedDoc[_depthStr]
                    if (_name === null) break; 
                    _updatedDoc.name = _name;
                } 
                _updatedDocs.push(_updatedDoc);
                _key++;
            }
        }
        else if(type === 'file'){
            let _key = 100000;
            for(let _doc of docs){
                let _updatedDoc = {
                    id : _doc.id,
                    type : type,
                    name : _doc.fileName,
                    fileType : _doc.fileType,
                    fileSize : _doc.fileSize,
                    createdAt :new Date(_doc.createdAt),
                    note : _doc.note,
                    uploaderName : Util.getFullName(_doc.uploaderFirstName, _doc.uploaderLastName),
                    uploaderID : _doc.uploaderID,
                    actualUploaderType : _doc.actualUploaderType,
                    billFolderMapID : _doc.billFolderMapID,
                    key : _key
                }
                _updatedDocs.push(_updatedDoc);
                _key++;
            }
        }
        
        return _updatedDocs;
    }

    const fetchFilesAndFolders = async(folderID) =>{
        try{
            setFetchingFiles(true);
            const endpoint = "advisor/folder/fetch";
            const payLoad = {
                folderID:folderID,
                folderOwner: currentClient.userId
            }
            const response = await ApiService.post(endpoint, payLoad);
            if(response.status === 200){
                const responseBody = await response.json();
                let updatedDocuments = documents;
                let formattedData = [];
                formattedData.push(...formatIncomingDocumentDetails(responseBody.data.folders, 'folder'));
                formattedData.push(...formatIncomingDocumentDetails(responseBody.data.files, 'file'));
                updatedDocuments[responseBody.data.parentFolderID] = formattedData;
                console.log(updatedDocuments);
                setDocuments(updatedDocuments);
                setFetchingFiles(false);
                return responseBody.data.parentFolderID;
            }
            else{
                //pop up error TODO
                setFetchingFiles(false);
                return -1; 
            }
        }
        catch (error){
            console.log(error);
            setFetchingFiles(false);
            return -1; 
        }
    }

    const fetchOrders = async() =>{
        const filteredOrders = orderList.filter(item => item.userId === currentClient.userId);
        setOrders(filteredOrders);
        /*try{
            const endpoint = "order/allAdvisorOrders";
            const parameters = {
                userId : currentClient.userId,
                orderBy : 'creationDateDesc'
            };
            const response = await ApiService.get(endpoint, parameters);
            if(response.status === 200){
                const responseBody = await response.json();
                setOrders(responseBody.data.pendingReq);
            }
            else{
                //pop up error TODO
            }
        }
        catch (error){
            console.log(error);
        }*/
    }

    const fetchOrderFiles = async(orderID) =>{
        try{
            let pageNo = 1;
            let allFiles = [];
            const endpoint = "order/advisor/allBillsForOrder";
            let parameters = {
                orderId : orderID,
                orderBy : 'uploadDesc'
            };
            while(true){
                parameters.page = pageNo;
                const response = await ApiService.get(endpoint, parameters);
                if(response.status === 200){
                    const responseBody = await response.json();
                    allFiles.push(...responseBody.data);
                    if (responseBody.data.length < 30)
                        break;
                    else 
                        pageNo++;
                }
                else{
                    break;
                    //pop up error TODO
                }
            }
            let updatedOrderDocuments = orderDocuments;
            updatedOrderDocuments[orderID] = allFiles;
            setOrderDocuments(updatedOrderDocuments);
        }
        catch (error){
            console.log(error);
        }
    }

    const onPressName = async(folderID, name, isFromTrail) =>{
        //console.log('pressed' + folderID.toString());
        ClearCheckedRows();
        retrieveDocuments(folderID);
        let _currentTrail = folderTrail;
        if (isFromTrail){
            //delete the last elements which are not needed
            let _newTrail = [];
            for (let _currFolder of _currentTrail){
                if (_currFolder.folderID === folderID){
                    _newTrail.push(_currFolder);
                    break;
                }
                else{
                    _newTrail.push(_currFolder);
                }
            }
            setFolderTrail(_newTrail);
        }
        else{
            _currentTrail.push({
                name:name, 
                folderID:folderID
            });
            setFolderTrail(_currentTrail);
        }
    }

    const handleSaveAccessModal = async (giveAccessObj) => {
		try {
            const response = await ApiService.post('advisor/givePermission', giveAccessObj);
            if (response.ok) {
                showToast('Access granted successful!', 'success');
                setCheckedRows([]);
            } else {
                showToast('Failed to grant acess!', 'failure');
            }
        } catch (error) {
            showToast('Failed to grant acess', 'failure');
        }

	};

    const IsItemInCheckedRows = (_rowID, _type) =>{
        return checkedRows.some(element => 
            element.key === _rowID && element.type === _type
        );
    }

    const RenderRenameButton = () =>{
        if(checkedRows.length === 1 && checkedRows[0].type === 'folder')
            return true;
        else 
            return false;
    }

    const RenderEditFileBtn = () =>{
        if(checkedRows.length === 1 && checkedRows[0].type === 'file')
            return true;
        else 
            return false;
    }

    const RenderDeleteBtn = () =>{
        if(checkedRows.length > 0){
            if(checkedRows.some(row => row.type === 'folder'))
                return false;
            else
                return true;
        }
        return false;
    }

    const RenderDeleteFolderBtn = () =>{
        if(checkedRows.length == 1){
            if(checkedRows.some(row => row.type === 'folder'))
                return true;
            else
                return false;
        }
        return false;
    }

    const RenderDownloadBtn = () =>{
        if (checkedRows.length === 0) return false;

        if (checkedRows.length === 1) return true;

        if(checkedRows.length > 1){
            if(checkedRows.some(row => row.type === 'folder'))
                return false;
            else
                return true;
        }
    }

    const RenderMoveFilesBtn = () =>{
        if (checkedRows.length === 0) return false;

        if(checkedRows.length > 0){
            if(checkedRows.some(row => row.type === 'folder'))
                return false;
            else
                return true;
        }
    }

    const RenderGiveAccessBtn = () =>{
        if(checkedRows.length > 0 && checkedRows[0].type === 'file')
            return true;
        else 
            return false;
    }

    const ClearCheckedRows = () =>{
        setCheckedRows([]);
    }

    const onDrop = useCallback((acceptedFiles, row=null) => {
        if(row !== null && row.original.type === 'folder')
            setDropParentFolderID(row.original.id);
        setModalVisible(true);
        setDroppableFiles(acceptedFiles);
    }, []);

    const handleDocumentRenderClose = () =>{
        setDocumentToRender(null);
        setIsDocumentRenderModalVisible(false);
    }

    const handleSoftFileFullDownload = (fileObj) => {
        if(fileObj !== null && fileObj !== undefined){
            triggerClientDownload(fileObj.blob, fileObj.fileName, fileObj.fileType);
        }
    }
    
    const {getRootProps, getInputProps, isDragActive} = useDropzone({ onDrop : (files) => onDrop(files),
        noClick: true,
        noKeyboard: true });

    const CustomRow = ({ row, prepareRow }) => {
        const { getRootProps, getInputProps, isDragActive } = useDropzone({
            onDrop: (acceptedFiles) => onDrop(acceptedFiles, row),
            noClick: true,  // Prevents the dropzone from opening the file dialog when clicked
            noKeyboard: true // Prevents keyboard interactions
        });

        prepareRow(row);

        return (
          <tr {...row.getRowProps()} {...getRootProps()} className={`${isDragActive ? "border-2 border-orange-500" : ""}`}>
            {row.cells.map(cell => (
              <td {...cell.getCellProps()} className={`break-all border-0 px-0 `}>
                <CustomCell value={cell.value} column={cell.column} row={row} getInputProps={getInputProps}/>
              </td>
            ))}
          </tr>
        );
    };

    const CustomCell = ({ value, column, row, getInputProps }) => {
        if(column.id === 'select'){
            return <>
                    <input {...getInputProps()} />
                    <span className="group flex justify-center cursor-pointer"
                        onClick={() =>{
                            if (IsItemInCheckedRows(row.original.key, row.original.type)){
                                let _checkedRows = checkedRows.filter(element => !(element.key === row.original.key && element.type === row.original.type));
                                setCheckedRows(_checkedRows);
                            }
                            else{
                                setCheckedRows([...checkedRows, row.original]);
                            }
                        }}>
                        {IsItemInCheckedRows(row.original.key, row.original.type) ? <IoIosCheckmarkCircle className="text-orange-500 h-6 w-6" style={{ strokeWidth: '2' }}/>
                            : <FaRegCircle className="text-gray-300 group-hover:text-orange-500 h-5 w-5" style={{ strokeWidth: '2' }}/>}
                    </span>
                    </>
        }
        if(row.original.type === 'folder'){
            if(column.id === 'name'){
                return <span className="flex items-center space-x-2 select-none">
                            <FcFolder className="h-5 w-5"/>
                            <span className="hover:underline cursor-pointer" onClick={() => onPressName(row.original.id, row.original.name, false)}>{value}</span>
                        </span>
            }
        }
        else if(row.original.type === 'file'){
            if (column.id === 'uploadDate') {
                const formattedDate = row.original.createdAt.toLocaleString();
                return <span className="flex justify-center items-center space-x-2 select-none cursor-pointer">
                            <span className="text-sm">{Util.formatDate(formattedDate)}</span>
                        </span>
                }
            else if(column.id === 'name'){
                return <span className="flex items-center space-x-2 select-none cursor-pointer">
                            {fileIcon(row.original.fileType)}
                            {/*value.endsWith('.pdf') ? <FiFileText /> : <FcFolder className="h-5 w-5"/>*/}
                            <span className="hover:underline cursor-pointer" onClick={async() => {
                                                                                    let softDownloadedFile = null;
                                                                                    if(softDownloadedFiles !== undefined){
                                                                                        for(let _sf of softDownloadedFiles){
                                                                                            if (_sf.billFolderMapID === row.original.billFolderMapID)
                                                                                                softDownloadedFile = _sf.file;
                                                                                        }
                                                                                    }
                                                                                    if (softDownloadedFile === null){
                                                                                        //setFetchingFiles(true);
                                                                                        softDownloadedFile = await downloadFile([row.original.billFolderMapID], 'billFolderMapIds', row.original.name, true);
                                                                                        //setFetchingFiles(false);
                                                                                        const _currSoftDownloadedFiles = softDownloadedFiles;
                                                                                        softDownloadedFiles.push({
                                                                                            billFolderMapID : row.original.billFolderMapID,
                                                                                            file : softDownloadedFile
                                                                                        });
                                                                                        setSoftDownloadedFiles(_currSoftDownloadedFiles);
                                                                                    }
                                                                                    setDocumentToRender(softDownloadedFile);
                                                                                    setIsDocumentRenderModalVisible(true);
                                                                                    }}>
                                {value}
                            </span>
                        </span>
            }
            else if(column.id === 'size'){
                return <span className="flex justify-center items-center space-x-2 select-none cursor-pointer">
                            <span className="text-sm">{convertFileSize(row.original.fileSize)}</span>
                        </span>
            }
            else if(column.id === 'uploadedBy'){
                return <span className="flex justify-center items-center space-x-2 select-none cursor-pointer">
                            <span className="text-sm">{row.original.uploaderName}</span>
                        </span>
            }
            else if(column.id === 'notes'){
                return <span className="flex items-center space-x-2 select-none cursor-pointer">
                            <span className="text-sm pl-2">{row.original.note}</span>
                        </span>
            }
            return <span>{value}</span>;
        }
        
    };
      
    return (
        <>
            <div className="flex justify-end space-x-1 mb-2.5 w-full">
            {RenderGiveAccessBtn() &&
            <div>
                <button className="flex items-center px-2.5 py-2.5 bg-orange-500 text-white text-sm leading-none tracking-wide rounded cursor-pointer hover:bg-orange-700" onClick={handleOpenGiveAccessModal}>
                <SiOpenaccess className="mr-1.5" /> Give Access
                </button>
            </div>}
            {RenderDownloadBtn() &&
            <div>
                <button className="flex items-center px-2.5 py-2.5 bg-orange-500 text-white text-sm leading-none tracking-wide rounded cursor-pointer hover:bg-orange-700" onClick={onPressDownload}>
                <FaCloudDownloadAlt className="mr-1.5" /> Download
                </button>
            </div>}
            {RenderDownloadBtn() &&
            <div>
                <button className="flex items-center px-2.5 py-2.5 bg-orange-500 text-white text-sm leading-none tracking-wide rounded cursor-pointer hover:bg-orange-700" onClick={onPressDownload}>
                <FaCloudDownloadAlt className="mr-1.5" /> Download
                </button>
            </div>}
            {RenderRenameButton() &&
            <div>
                <button className="flex items-center px-2.5 py-2.5 bg-orange-500 text-white text-sm leading-none tracking-wide rounded cursor-pointer hover:bg-orange-700" onClick={handleOpenRenameModal}>
                <FiEdit3 className="mr-1.5" /> Rename
                </button>
            </div>}
            {RenderEditFileBtn() &&
            <div>
                <button className="flex items-center px-2.5 py-2.5 bg-orange-500 text-white text-sm leading-none tracking-wide rounded cursor-pointer hover:bg-orange-700" onClick={handleOpenFileEditModal}>
                <FiEdit3 className="mr-1.5" /> Edit
                </button>
            </div>}
            {RenderMoveFilesBtn() &&
            <div>
                <button className="flex items-center px-2.5 py-2.5 bg-orange-500 text-white text-sm leading-none tracking-wide rounded cursor-pointer hover:bg-orange-700" onClick={handleOpenFileMoveModal}>
                <IoMdMove className="mr-1.5" /> Move
                </button>
            </div>}
            {RenderDeleteBtn() &&
            <div>
                <button className="flex items-center px-2.5 py-2.5 bg-orange-500 text-white text-sm leading-none tracking-wide rounded cursor-pointer hover:bg-orange-700" onClick={handleOpenDeleteFilesModal}>
                <RiDeleteBinFill className="mr-1.5" /> Delete
                </button>
            </div>}
            {RenderDeleteFolderBtn() &&
            <div>
                <button className="flex items-center px-2.5 py-2.5 bg-orange-500 text-white text-sm leading-none tracking-wide rounded cursor-pointer hover:bg-orange-700" onClick={handleOpenDeleteFolderModal}>
                <RiDeleteBinFill className="mr-1.5" /> Delete Folder
                </button>
            </div>}
            <div>
                <button className="flex items-center px-2.5 py-2.5 bg-orange-500 text-white text-sm leading-none tracking-wide rounded cursor-pointer hover:bg-orange-700" onClick={handleOpenNewFolderModal}>
                <FaPlusCircle className="mr-1.5" /> Add new folder
                </button>
            </div>
            <div>
                <button className="flex items-center px-2.5 py-2.5 bg-orange-500 text-white text-sm leading-none tracking-wide rounded cursor-pointer hover:bg-orange-700" onClick={handleOpenModal}>
                <FaCloudUploadAlt className="mr-1.5" /> Upload
                </button>
            </div>
            <div>
                <button className="flex items-center px-2.5 py-2.5 bg-orange-500 text-white text-sm leading-none tracking-wide rounded cursor-pointer hover:bg-orange-700" onClick={handleOpenDocumentMapperModal}>
                <FaCloudUploadAlt className="mr-1.5" /> File Mapper
                </button>
            </div>
            </div>
            {isGiveAccessModalOpen && <GiveAccessModal isOpen={isGiveAccessModalOpen} onClose={handleCloseGiveAccessModal} clientId={null} onSave={handleSaveAccessModal} documentIds={documentIds}/>}
            <DocumentUploadModal
                isVisible={isModalVisible}
                onClose={handleCloseModal}
                onSave={handleSaveTask}
                dropParentFolderID = {dropParentFolderID}
                droppableFiles = {droppableFiles}
            />
            <DocumentOperationModal
                isVisible={isRenameModalVisible}
                onClose={handleCloseRenameModal}
                onSave={handleRenameTask}
                header={"Rename Folder"}
                selectedRow={checkedRows.length === 0 ? null : checkedRows[checkedRows.length-1]}
            />
            <DocumentOperationModal
                isVisible={isNewFolderModalVisible}
                onClose={handleCloseNewFolderModal}
                onSave={handleNewFolderTask}
                header={"New Folder"}
            />
            {isDeleteFilesModalVisible &&
                <DocumentFileDeletionModal
                    onClose={handleCloseDeleteFilesModal}
                    onSave={handleFileDeletionTask}
                    selectedItems={checkedRows.length === 0 ? [] : checkedRows}
                    />
            }
            {isDeleteFolderModalVisible &&
                <DocumentFolderDeletionModal
                    onClose={handleCloseDeleteFolderModal}
                    onSave={handleFolderDeletionTask}
                    selectedItem={checkedRows.length === 0 ? null : checkedRows[0]}
                    />
            }
            {isFileEditModalVisible &&
                <DocumentFileEditModal
                    isVisible={isFileEditModalVisible}
                    onClose={handleCloseFileEditModal}
                    onSave={editFileDetails}
                    selectedRow={checkedRows.length === 0 ? null : checkedRows[checkedRows.length-1]}
            />}
            {isDocumentMapperModalVisible &&
                <DocumentMapperModal
                onClose = {handleCloseDocumentMapperModal}
                onMoveItems={attachFilesToFolder}
                allDocuments={documents}
                onFetchFolder = {fetchFilesAndFolders}
                onFetchOrders = {fetchOrders}
                onFetchOrderFiles={fetchOrderFiles}
                allOrders={orders}
                allOrderDocuments={orderDocuments}
                topLevelFolderID = {topLevelFolderID}
                sourceFilesForMove = {null}
            />}
            {isFileMoverModalVisible &&
                <DocumentMapperModal
                onClose = {handleCloseFileMoveModal}
                onMoveItems={handleFileMove}
                allDocuments={documents}
                onFetchFolder = {fetchFilesAndFolders}
                onFetchOrders = {fetchOrders}
                onFetchOrderFiles={fetchOrderFiles}
                allOrders={orders}
                allOrderDocuments={orderDocuments}
                topLevelFolderID = {topLevelFolderID}
                sourceFilesForMove = {checkedRows}
            />}
            {isDocumentRenderModalVisible &&
                <DocumentRenderModal
                fileObj={documentToRender}
                onClose={handleDocumentRenderClose}
                onDownload={handleSoftFileFullDownload}/>
            }
            <div className="flex justify-start w-full p-2">
                <FolderTrailComponent items={folderTrail} onItemClick={onPressName} />
            </div>
            <div className="flex items-center mt-2 mb-2 w-full border border-gray-300 rounded focus-within:border-orange-500 focus-within:ring focus-within:ring-orange-200 focus-within:ring-opacity-50">
                <FiSearch className="ml-2 text-gray-400" />
                <input
                    type="text"
                    onChange={e => setGlobalFilter(e.target.value)}
                    placeholder="Search all columns"
                    className="p-2 w-full focus:outline-none"
                />
            </div>
            <div className="max-w-full overflow-x-auto" style={{maxHeight : 'calc(100vh - 200px)'}}>
                {isFetchingFiles && (
                    <div className="absolute inset-0 flex justify-center items-center">
                    <Loader></Loader>
                    </div>
                )}
                <table {...getTableProps()} className="w-full">
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{width: column.width, maxWidth: column.maxWidth}}className={`sticky top-0 bg-white border-0 text-left p-2 select-none cursor-pointer ${column.isSorted ? column.isSortedDesc ? 'sort-desc' : 'sort-asc' : ''}`}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map(row => 
                            <CustomRow key={row.original.key} row={row} prepareRow={prepareRow}/>
                        )}
                    </tbody>
                </table>
                <div {...getRootProps()} className={`flex justify-center items-center h-80 `}>
                    <input {...getInputProps()} />
                    {isDragActive ? <span className="text-xl font-bold">{isDragActive ? 'Drop here' : ''}</span> : null}
                </div>
            </div>
    </>

    );
};

export default Documents;
