import React from 'react';
import '../Styles/Loader.css';
import logo from '../images/iova-logo-2.png';

const Loader = () => {
  return (
    <div className="loader-container">
      <img src={logo} alt="Loading..." className="loader" />
    </div>
  );
};

export default Loader;
