import React, { useState, useCallback, useEffect } from 'react';

const DocumentFolderDeletionModal = ({ onClose, onSave, selectedItem = null }) => {

	console.log(selectedItem);
	const handleSave = () => {
		if (selectedItem)
			onSave(selectedItem.id);
		handleClose();
	};

	const handleClose = () =>{
		onClose();
	}

	return (
		<div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
			<div className="bg-white p-6 rounded-lg shadow-md w-11/12 max-w-lg flex flex-col space-y-4">
				<div className="flex flex-col items-center space-y-2">
					<h2 className="text-lg font-semibold">Folder to be deleted</h2>
					{/* Scrollable list container */}
					<div className="w-full max-h-80 overflow-auto">
						<div className="flex flex-col space-y-1 p-2 border-2 border-orange-500 rounded-md my-2">
							<span className="text-md text-gray-800">{selectedItem.name}</span>
						</div>
					</div>
				</div>
				<div className="flex justify-end space-x-2">
					<button className="px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-700" onClick={handleSave}>Delete Folder</button>
					<button className="px-4 py-2 bg-orange-300 text-white rounded hover:bg-orange-400" onClick={handleClose}>Cancel</button>
				</div>
			</div>
		</div>
	);
  
};

export default DocumentFolderDeletionModal;
