import React, { useState, useEffect } from 'react';
import Orders from './Orders';
import { useData } from './DataContext';
import { useToast } from './ToastContext';
import Toast from './ToastComponent';
import ApiService from '../Services/ApiService';
import Loader from './Loader';

const ClientOrders = () => {
    const { clients, skillList } = useData();
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const { toast, showToast } = useToast();

    const searchParams = new URLSearchParams(window.location.search);
    const clientId = searchParams.get('clientId');

    const fetchOrders = async (id) => {
        const response = await ApiService.get("order/allAdvisorOrders?userID=" + id + "&orderBy=creationDateDesc");
        const clientOrders = await response.json();
        return [...clientOrders.data.pendingReq, ...clientOrders.data.acceptedReq];
    };

    useEffect(() => {
        const fetchClientsOrders = async (id) => {
            setLoading(true);
            try {
                const orderList = await fetchOrders(id);
                setOrders(orderList);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                showToast('Failed to load client orders!', 'failure');
            }
        };

        if (clientId) {
            fetchClientsOrders(clientId);
        }
    }, [clientId]);

    return (
        <>
            {loading ?
                <Loader></Loader>
                :
                (<><Orders
                    ordersList={orders}
                    clients={clients}
                    skillList={skillList} /><Toast message={toast.message} type={toast.type} /></>)
            }
        </>

    );
};

export default ClientOrders;
