import ApiService from '../Services/ApiService';
import nacl from 'tweetnacl';
import { encodeBase64, decodeBase64 } from 'tweetnacl-util';
import { FaFileImage } from '@react-icons/all-files/fa/FaFileImage';
import {FaFilePdf} from '@react-icons/all-files/fa/FaFilePdf';
import {FaFile} from '@react-icons/all-files/fa/FaFile';
import {FaFileCsv } from '@react-icons/all-files/fa/FaFileCsv';
import {AiFillFileZip  } from '@react-icons/all-files/ai/AiFillFileZip';
import {RiFileExcel2Fill } from '@react-icons/all-files/ri/RiFileExcel2Fill';
import {RiFileWord2Fill  } from '@react-icons/all-files/ri/RiFileWord2Fill';
import {RiFilePpt2Fill } from '@react-icons/all-files/ri/RiFilePpt2Fill';
import {RiFileTextFill  } from '@react-icons/all-files/ri/RiFileTextFill';
import imageCompression from 'browser-image-compression';






//#region upload file

export const uploadFile = async(file, toBeUsedIn=null) =>{
    file.fileObj = await compressFile(file.fileObj);
    const fileNonce = encodeBase64(nacl.randomBytes(nacl.secretbox.nonceLength));
    const keyPair = nacl.box.keyPair();
    const publicKey = encodeBase64(keyPair.publicKey);
    const privateKey = keyPair.secretKey;
    const initFileUploadResponse = await initUploadFile(file, publicKey, fileNonce, toBeUsedIn);
    if(initFileUploadResponse.isSuccess === false){
        throw new Error('Unable to Init file upload procedure');
    }

    try{			
        const sharedSecret = nacl.scalarMult(privateKey, decodeBase64(initFileUploadResponse.public_key_serialized));
        const encryptedFile = await encryptFile(file.fileObj, decodeBase64(fileNonce), sharedSecret);
        const encryptedBlob = base64ToBlob(encryptedFile.encryptedData64, file.fileObj.type);
        const response = await fetch(initFileUploadResponse.presignedUploadUrl, {
            method: 'PUT',
            body: encryptedBlob,
            headers: {
              'Content-Type': file.fileObj.type
            },
        });
        if (response.status === 200) {
            return {
                    id : initFileUploadResponse.uploadedFileId,
                    type : 'file',
                    name : initFileUploadResponse.fileName,
                    fileType : initFileUploadResponse.fileType,
                    fileSize : initFileUploadResponse.fileSize,
                    createdAt : new Date(),
                    note : initFileUploadResponse.note,
                    uploaderName : 'ClientName',
                    uploaderID : 20,
                    actualUploaderType : 'advisor'
            }
            //return initFileUploadResponse.uploadedFileId;
        } 
        else {
            throw new Error('Unable to upload the encoded file');
        }
    }
    catch(err){
        console.log(err);
        throw new Error(err);
    }


}

const initUploadFile = async(file, userPublicKey, fileNonce, toBeUsedIn) =>{
    try{
        let payload = {};
        payload.fileName = file.fileObj.name;
        payload.fileType = file.fileObj.type;
        payload.fileSize = file.fileObj.size;
        payload.userPublicKey = userPublicKey;
        payload.fileNonce = fileNonce;
        if (toBeUsedIn != null)
            payload.toBeUsedIn = toBeUsedIn;
        if(file.fileNote !== null)
            payload.fileNote = file.fileNote;
        const response = await ApiService.post('advisor/bill/initUpload',payload);
        if(response.status === 201){
            const responseBody = await response.json();
            console.log(responseBody);
            return {
                isSuccess : true,
                uploadedFileId : responseBody.data.id,
                fileKey : responseBody.data.fileKey,
                presignedUploadUrl : responseBody.data.presignedUploadUrl,
                public_key_serialized : responseBody.data.public_key_serialized,
                fileSizeQuotaExceeded : responseBody.data.fileSizeQuotaExceeded,
                fileName : payload.fileName,
                fileType : payload.fileType,
                fileSize : payload.fileSize,
                note : file.fileNote
            };
        }
    }
    catch(err){
        console.log(err);
        throw new Error(err);
    }
}

const encryptFile = async(file, nonce, secretKey) => {
    try{
        const uint8Array = await fileToArrayBuffer(file);
        const encryptedData = nacl.secretbox(uint8Array, nonce, secretKey);
        const fullMessage = new Uint8Array(encryptedData.length);
        fullMessage.set(encryptedData);
        const encryptedData64 = encodeBase64(fullMessage);
        return {
            secretKey : encodeBase64(secretKey),
            nonceKey : encodeBase64(nonce),
            encryptedData64 : encryptedData64
        };
    }
    catch (error) {
        console.log(error);
    }
};

const fileToArrayBuffer = (file)=> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(new Uint8Array(reader.result));
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
    });
}

const base64ToBlob = (base64, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(base64); // Decode base64 string
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
    
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
    
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

export const compressFile = async(fileObj) =>{
    if(fileObj.type.startsWith('image')){
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
          }
          try {
            const compressedFile = await imageCompression(fileObj, options);
        
            return compressedFile;
          } catch (error) {
            console.log(error);
          }
    }
    else
        return fileObj;
}

//#endregion

//#region download file

export const downloadFile = async(ids, toBeUsedIn, overRideFileNameStr=null, isSoftDownload=false) => {
    if (ids === null || ids.length === 0) return;

    const keyPair = nacl.box.keyPair();
    const publicKey = encodeBase64(keyPair.publicKey);
    const privateKey = keyPair.secretKey;

    let payload = {
        userPublicKey : publicKey
    };
    payload[toBeUsedIn] = ids;

    try{
        const response = await ApiService.post('advisor/bill/encryptedDownloadLinks',payload);
        if(response.status === 201){
            const responseBody = await response.json();
            for(let fileLinkObj of responseBody.data){
                if(isSoftDownload){
                    const downloadedData = await downloadAndDecryptFile(fileLinkObj, privateKey, overRideFileNameStr, isSoftDownload);
                    return downloadedData;//only 1 file can be downloaded in this case
                }
                else
                    await downloadAndDecryptFile(fileLinkObj, privateKey, overRideFileNameStr);
            }
        }
        else{
            throw new Error('Unable to generate download link.');
        }
    }
    catch(err){
        console.log(err);
        throw new Error(err);
    }
}

const downloadAndDecryptFile = async(fileLinkObj, privateKey, overRideFileNameStr=null, isSoftDownload=false) =>{
    let fileID = fileLinkObj.fileId;
    let fileName = overRideFileNameStr === null ? fileLinkObj.fileName : overRideFileNameStr;
    let fileType = fileLinkObj.fileType;
    let FILE_URL = fileLinkObj.downloadLink;
    let fileEncryptionKey = fileLinkObj.fileEncryptionKey;
    let fileEncryptionNonce = fileLinkObj.fileEncryptionNonce;
    let packagePublicKey = fileLinkObj.packagePublicKey;
    let packageNonce = fileLinkObj.packageNonce;

    try{
        const sharedSecret = nacl.scalarMult(privateKey, decodeBase64(packagePublicKey));
        const packageNonce_decoded = decodeBase64(packageNonce);
        const fileEncryptionKey_decoded = decodeBase64(fileEncryptionKey);
        const fileEncryptionKey_decrypted = nacl.secretbox.open(fileEncryptionKey_decoded, packageNonce_decoded, sharedSecret);
        const fileEncryptionNonce_decoded = decodeBase64(fileEncryptionNonce);

        const response = await fetch(FILE_URL);
        if (!response.ok) {
            throw new Error(`Failed to fetch: ${response.statusText}`);
        }

        const _arrayBuffer = await response.arrayBuffer();
        const uint8Array = new Uint8Array(_arrayBuffer);

        const decrypted = nacl.secretbox.open(uint8Array, fileEncryptionNonce_decoded, fileEncryptionKey_decrypted);
    
        if (!decrypted) {
            throw new Error('Decryption failed');
        }

        if(isSoftDownload)
            return {
                blob : decrypted,
                fileName : fileName,
                fileType : fileType
            };
        else
            await triggerClientDownload(decrypted, fileName, fileType);


    }
    catch(err){
        console.log(err);
        throw new Error(err);
    }
}

export const triggerClientDownload = async(decryptedFile, fileName, fileType) =>{
    const blob = new Blob([decryptedFile], { type: fileType });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
}

//#endregion


//#region Utilities

export const fileIcon = (fileMimeType) =>{

    let icon = <FaFile className="h-5 w-5"/>;
    switch(fileMimeType){
        case 'image/jpeg':
        case 'image/png':
        case 'image/webp':
        case 'image/gif':
        case 'image/bmp':
            icon = <FaFileImage className="h-5 w-5" color="#13B508"/>;
            break;
        case 'application/pdf':
            icon = <FaFilePdf className="h-5 w-5" color='#B51308'/>;
            break;
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'application/msword':
            icon = <RiFileWord2Fill className="h-5 w-5" color='#2163C0'/>;
            break;
        case 'application/vnd.ms-powerpoint':
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            icon = <RiFilePpt2Fill className="h-5 w-5" color='#C74926'/>;
            break;
        case 'text/plain':
            icon = <RiFileTextFill className="h-5 w-5" color='#3D82F4'/>;
            break;
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'application/vnd.ms-excel':
            icon = <RiFileExcel2Fill className="h-5 w-5" color='#187A44'/>;
            break;
        case 'text/csv':
            icon = <FaFileCsv className="h-5 w-5" color='#088E32'/>;
            break;
        case 'application/gzip':
        case 'application/zip':
        case 'application/x-7z-compressed':
            icon = <AiFillFileZip className="h-5 w-5" color='#F8B31E'/>;
            break;
        default:
            break;
    }

    return icon;
}

export const convertFileSize = (size) =>{
    if(size < 100){
        return size + 'B'
    }
    
    size = size / 1024;
    
    if(size < 1000){
        return Math.round(size * 100) / 100 + 'KB'
    }
    
    size = size / 1024;
    
    if(size < 100){
        return Math.round(size * 100) / 100 + 'MB'
    }
    
    size = size / 1024;
    
    return Math.round(size * 100) / 100 + 'GB'
}

//#region 