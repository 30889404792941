import React, { useState, useRef, useEffect } from 'react';
import '../Styles/UserProfile.css';
import { FaSignInAlt } from '@react-icons/all-files/fa/FaSignInAlt';
import { FaSignOutAlt } from '@react-icons/all-files/fa/FaSignOutAlt';
import avatar from '../images/advisor-image.png'
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  List,
  ListItem,
  Spinner,
  Text
} from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react';
import ApiService from '../Services/ApiService';



const urlB64ToUint8Array = (base64String) =>{
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export const subscribeToPushNotification = async () =>{
  if ('serviceWorker' in navigator && 'PushManager' in window) {
    try {
      const swReg = await navigator.serviceWorker.register('/sw.js');
      console.log('Service Worker is registered', swReg);

      let subscription = await swReg.pushManager.getSubscription();

      if (subscription === null) {
        const applicationServerKey = urlB64ToUint8Array('BBWW7w5e6K6_vqW7cXEMtvS7xVSzRr-UmYSNNRp9nVw04nuLPBB00tTiyNe1JQJOxCfHtUwGquemIXhTtKQb-d8');
        subscription = await swReg.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: applicationServerKey
        });
        console.log('User is subscribed:', subscription);
        try{
          const endpoint = "advisor/pushnotificationsubscribe";
          const response = await ApiService.post(endpoint, subscription);
          const responseBody = await response.json();
          console.log(responseBody);
        }
        catch(error){

        }
      } 
      else {
        console.log('User is already subscribed:', subscription);
      }
    } 
    catch (error) {
      console.error('Service Worker registration or subscription failed', error);
    }
  }
}

const UserProfile = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const [isNotificationLoading, setIsNotificationLoading] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const { isLoggedIn, login, logout } = useAuth();
  let notificationPageNumber = 1;
  const listRef = useRef(null);
  const [hasMore, setHasMore] = useState(true);

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [dropdownRef]);

  //fetch notifications
  const unreadNotificationCountFetch = async() =>{
    const endpoint = "advisor/unreadNotificationCount";
    const response = await ApiService.get(endpoint);
    if(response.status === 200){
      const responseBody = await response.json();
      setUnreadNotificationCount(responseBody.data.count);
    }
  }

  useEffect(() =>{
    unreadNotificationCountFetch();
  },[]);

  useEffect(() => {
    const listElement = listRef.current;
    if (listElement) {
      listElement.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (listElement) {
        listElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [notificationList]);

  const handleScroll = () => {
    const listElement = listRef.current;
    if (listElement.scrollTop + listElement.clientHeight >= listElement.scrollHeight && !isNotificationLoading && hasMore) {
      notificationPageNumber++;
      notificationFetch(notificationPageNumber);
    }
  };

  const handleLoginLogoutClick = () => {
    if (isLoggedIn) {
      onLogout();
    } else {
      onLogin(); // This could open a login modal or redirect to a login page
    }
  };

  const onLogin = () => {
    navigate('/');
  }

  const onLogout = () => {
    console.log("logging out..");
    document.cookie = 'accessToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Secure; SameSite=None';
    document.cookie = 'refreshToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Secure; SameSite=None';
    logout();
    navigate('/');
  }

  const notificationFetch = async(pageNumber) =>{
    if (hasMore===false) return;
    const endpoint = "advisor/getAllNotifications";
    const payLoad = {
      pageNumber:pageNumber
    }
    const response = await ApiService.post(endpoint, payLoad);
    if(response.status === 200){
      const responseBody = await response.json();
      if(pageNumber === 1){
        setNotificationList(responseBody.data);
      }
      else{
        setNotificationList((prevList) => [...prevList, ...responseBody.data]);
        if(responseBody.data.length >= 0 && responseBody.data.length < 30){
          setHasMore(false);
        }
      }
    }
    setIsNotificationLoading(false);
  }

  const onNotificationMarkAsRead = async(notification) =>{
    let curr_notifications = notificationList;
    for(let _noti of curr_notifications){
      if (_noti.id === notification.id){
        _noti.markedRead = true;
        break;
      }
    }
    setNotificationList(curr_notifications);

    const endpoint = "advisor/markNotificationAsRead";
    const payLoad = {
      id:notification.id
    }
    const response = await ApiService.post(endpoint, payLoad);
    if(response.status === 200){
      unreadNotificationCountFetch();
      }
    else{
      //error
    }
  }

  const handleNotificationClick = () =>{
    setShowDropdown(false);
    if(notificationIsGranted()){
      notificationPageNumber = 1;
      setHasMore(true);
      onOpen();
      setIsNotificationLoading(false);
      notificationFetch(notificationPageNumber);
    }
    else{
      if(Notification.permission === 'denied'){
        //show them how to enable in case they blocked it
      }
      else{
        subscribeToPushNotification();
        onClose();
      }
    }
  }

  const notificationIsGranted = () =>{
    return Notification.permission === 'granted';
  }

  const renderNotificationList = () =>{
    return(
      <List spacing={3} overflowY="auto" ref={listRef}>
        {notificationList.map((notification, index) => (
          <ListItem key={index} border="2px solid" borderColor="orange.500" borderRadius="md" padding="2">
            {notification.msg}
            {!notification.markedRead && (
              <Text
                as="button"
                color="orange.500"
                onClick={() => onNotificationMarkAsRead(notification)}
                position="relative"
                bottom="0"
                right="0"
                _hover={{ textDecoration: 'underline' }}
              >
                mark as read
              </Text>
              )}
          </ListItem>
        ))}
        {isNotificationLoading && (
          <ListItem>
            <Spinner />
          </ListItem>
        )}
        </List>
    );
  }

  return (
    <div className="user-profile-container" ref={dropdownRef}>
      {isLoggedIn ? (
        <>
          <img
            src={avatar} // Replace with the path to your avatar image
            alt="User Profile"
            className="profile-avatar"
            onClick={() => {
              setShowDropdown(!showDropdown);
              unreadNotificationCountFetch();
            }}
          />
          {showDropdown && isLoggedIn && (
            <div className="dropdown-menu">
              <a href="/profile">Profile</a>
              <div onClick={handleNotificationClick}>{notificationIsGranted() ? `Notifications (${unreadNotificationCount})` : 'Enable Notifications'}</div>
              <a href="/settings">Settings</a>
              <div onClick={handleLoginLogoutClick}><FaSignOutAlt /> Logout</div>
            </div>
          )}
          <Drawer
            isOpen={isOpen}
            placement='right'
            onClose={onClose}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>Notification Center</DrawerHeader>

              <DrawerBody>
                {renderNotificationList()}
              </DrawerBody>

              <DrawerFooter>
                
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        </>
      )
        : (
          <button
            className="login-button"
            onClick={onLogin}
          >
            <FaSignInAlt /> Login
          </button>
        )}
    </div>
  );
};

export default UserProfile;