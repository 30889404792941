import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Box,
    FormControl,
    FormLabel,
    Select,
    useToast,
} from '@chakra-ui/react';
import { useData } from './DataContext';

const GiveAccessModal = ({ isOpen, onClose, clientId, onSave, documentIds }) => {
    const { advisors } = useData();


    const [formData, setFormData] = useState({
        accessReceiverID: null,
        accessType: clientId ? 1 : 2,
        subAccessType: clientId ? null : 1,
        accessItemIDs: clientId ? [clientId] : documentIds,
    });

    const accessTypes = [
        { value: 1, label: 'Read Only' },
        { value: 2, label: 'Full' },
    ];

    // Handle change for form inputs
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: Number(value)
        }));
    };

    const handleSave = async () => {
        onSave(formData);
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="md">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Give Access</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl mb={4}>
                        <FormLabel>Employee</FormLabel>
                        <Select
                            placeholder="Select Employee"
                            name="accessReceiverID"
                            value={formData.accessReceiverID}
                            onChange={handleChange}
                        >
                            {advisors ? advisors.map((advisor) => (
                                <option key={advisor.employeeId} value={advisor.employeeId}>{advisor.employeeName}</option>
                            )) : []}
                        </Select>
                    </FormControl>
                    {clientId && <FormControl>
                        <FormLabel>Access Type</FormLabel>
                        <Select
                            placeholder="Select Access Type"
                            name="subAccessType"
                            value={formData.subAccessType}
                            onChange={handleChange}
                        >
                            {accessTypes.map(accessType => (
                                <option key={accessType.value} value={accessType.value}>{accessType.label}</option>
                            ))}
                        </Select>
                    </FormControl>}
                </ModalBody>
                <ModalFooter>
                    <Button className="btn-save" mr={3} onClick={handleSave}>
                        Save
                    </Button>
                    <Button className="btn-close" onClick={onClose}>
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default GiveAccessModal;