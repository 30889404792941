import React, {useState} from 'react'
import logo from '../images/iova-logo-2.png'
import { useNavigate  } from 'react-router-dom';
import UserProfile from './UserProfile';

const NavBar = () => {
    const [navBgr, setNavbgr] = useState(false);
    const changeBackground = () => {
        if(window.screenY >= 50){
            setNavbgr(true);
        }
        else{
            setNavbgr(false);
        }
    }
    
    window.addEventListener('scroll', changeBackground);
    const [activeItem, setActiveItem] = useState('Login');
    const handleItemClick = (itemName) => {
        setActiveItem(itemName);
        // Add code here to handle navigation if needed
    };
    // const history = useNavigate ();
    const redirectToIOVAGPT = () => {
        window.location.href = 'https://chat.iova.app/';
    };

    return (
        <nav className={navBgr ? 'nav-active' : 'nav'}>
            {/* <a href='#' className='logo'>
                <img src={logo} alt=''></img>
            </a> */}
            <ul className='menu'>
                {/* <li><a href='#contact' className={activeItem === 'Login' ? 'active' : ''} onClick={() => handleItemClick('ContactUs')}>Login</a></li> */}
                <UserProfile />
            </ul>
        </nav>
    )
}

export default NavBar;