import React, { useState, useEffect } from 'react';

const DocumentFileEditModal = ({ onClose, onSave, selectedRow}) => {


    const [newFileName, setNewFileName] = useState('');
    const [fileExtension, setFileExtension] = useState('');
    const [newNote, setNewNote] = useState('');

    const handleNameChange = (e) => {
        setNewFileName(e.target.value);
    };

    const handleNoteChange = (e) => {
        setNewNote(e.target.value);
    };

    const handleClose = () =>{
        setNewFileName('');
        setNewNote('');
        onClose();
    }

    const handleSave = () =>{
        const fileNameChanged = selectedRow.name !== (newFileName+fileExtension);
        const noteChanged = selectedRow.note !== newNote;
        if(fileNameChanged && noteChanged){
            if(newFileName.trim() === ''){
                //show error
                return;
            }
            onSave(newFileName+fileExtension, newNote, selectedRow.billFolderMapID);
        }
        else if(fileNameChanged && !noteChanged){
            if(newFileName.trim() === ''){
                //show error
                return;
            }
            onSave(newFileName+fileExtension, selectedRow.note, selectedRow.billFolderMapID);
        }
        else if(!fileNameChanged && noteChanged){
            onSave(selectedRow.name, newNote, selectedRow.billFolderMapID);
        }
        else{
            //nothing to change
        }
        handleClose();
    }

    useEffect(() =>{
        if(selectedRow !== null){
            const dotIndex = selectedRow.name.lastIndexOf('.');
            if (dotIndex > 0 && dotIndex < selectedRow.name.length - 1){
                setNewFileName(selectedRow.name.substring(0, dotIndex));
                setFileExtension(selectedRow.name.substring(dotIndex));
            }
            else{
                setNewFileName(selectedRow.name);
                setFileExtension('');
            }
            setNewNote(selectedRow.note);
        }
    },[]);

    return (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-md w-11/12 max-w-lg flex flex-col space-y-4">
                <div className="flex flex-col items-center space-y-2">
                    <h2 className="text-lg font-semibold">Edit</h2>
                    <div className="flex items-center mt-2 mb-2 w-full border border-gray-300 rounded-sm focus-within:border-orange-500 focus-within:ring focus-within:ring-orange-200 focus-within:ring-opacity-50">
                        <input
                            type="text"
                            placeholder="Enter the file name"
                            value={newFileName}
                            onChange={handleNameChange}
                            autoFocus
                            className="p-2 w-full focus:outline-none"
                        />
                        <span className="px-2 text-gray-500">{fileExtension}</span>
                    </div>
                    <div className="flex items-center mt-2 mb-2 w-full border border-gray-300 rounded-sm focus-within:border-orange-500 focus-within:ring focus-within:ring-orange-200 focus-within:ring-opacity-50">
                        <textarea
                            placeholder="Enter notes"
                            value={newNote}
                            onChange={handleNoteChange}
                            className="p-2 w-full focus:outline-none resize-y h-12"
                        />
                    </div>
                </div>
                <div className="flex justify-end space-x-2">
                    <button className="px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-700" onClick={handleSave}>Save</button>
                    <button className="px-4 py-2 bg-orange-300 text-white rounded hover:bg-orange-400" onClick={handleClose}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default DocumentFileEditModal;
