export const formatDate = (dateString) =>{
    let date = new Date(dateString);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour24 = date.getHours();
    let minute = date.getMinutes();

    let period = hour24<12 ? 'AM' : 'PM';
    let hour12 = hour24 % 12 || 12;
    
    let fullDateFormatted = day.toString().padStart(2,'0')+'-'+month.toString().padStart(2,'0')+'-'+year.toString()+' '+hour12.toString().padStart(2,'0')+':'+minute.toString().padStart(2,'0')+period;

    return fullDateFormatted;
}

export const getFullName = (firstName, lastName) =>{
    let fullName = '';
    if(firstName){
        fullName = firstName;
    }
    if(lastName){
        if(firstName){
            fullName += ' ' + lastName;
        }
        else{
            fullName = lastName;
        }
    }
    return fullName;
}

export const isDigitsOnly = (str) => {
    return /^\d+$/.test(str);
}