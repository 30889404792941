class AuthUtils {
  static baseUrl = 'http://127.0.0.1:8000/api/';
    // Function to retrieve access token from cookie
    static getAccessTokenFromCookie() {
      return new Promise((resolve, reject) => {
        const cookies = document.cookie.split(';');
        for (let cookie of cookies) {
          cookie = cookie.trim();
          if (cookie.startsWith('accessToken=')) {
            const accessToken = cookie.substring('accessToken='.length);
            resolve(accessToken);
            return;
          }
        }
        reject(new Error('Access token not found in cookies'));
      });
    }
  
    // Function to retrieve refresh token from cookie, now returns a Promise for consistency
    static getRefreshTokenFromCookie() {
      return new Promise((resolve, reject) => {
        const cookies = document.cookie.split(';');
        for (let cookie of cookies) {
          cookie = cookie.trim();
          if (cookie.startsWith('refreshToken=')) {
            const refreshToken = cookie.substring('refreshToken='.length);
            resolve(refreshToken);
            return;
          }
        }
        reject(new Error('Refresh token not found in cookies'));
      });
    }
    // Function to refresh access token using refresh token
    static async refreshAccessToken(refreshToken) {
      try {
        const response = await fetch(AuthUtils.baseUrl + 'advisor/accesstoken', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          }
        });
  
        if (!response.ok) {
          throw new Error('Failed to refresh access token.');
        }
  
        const tokenResult = await response.json();
        return tokenResult.accesstoken;
      } catch (error) {
        console.error('Error refreshing access token:', error);
        throw error;
      }
    }
  
    // Function to check if access token is expired
    static async isAccessTokenExpired() {
      try {
        const accessToken = await this.getAccessTokenFromCookie();
        const tokenPayload = JSON.parse(atob(accessToken.split('.')[1]));
        return Date.now() >= tokenPayload.exp * 1000;
      } catch (error) {
        console.error('Error checking access token expiration:', error);
        return true; // Assume expired if there's an error
      }
    }

    // Function to get loged in advisor id
    static async getLogedInAdvisorId() {
      try {
        const accessToken = await this.getAccessTokenFromCookie();
        const tokenPayload = JSON.parse(atob(accessToken.split('.')[1]));
        return tokenPayload.sender_id;
      } catch (error) {
        console.error('Error checking access token expiration:', error);
        return null; // Assume expired if there's an error
      }
    }

    // Function to get loged in advisor id
    static async getIsAdmin() {
      try {
        const accessToken = await this.getAccessTokenFromCookie();
        const tokenPayload = JSON.parse(atob(accessToken.split('.')[1]));
        return tokenPayload.orgID === tokenPayload.sender_id;
      } catch (error) {
        console.error('Error checking access token expiration:', error);
        return null; // Assume expired if there's an error
      }
    }

    // Function to handle authenticated API requests
    static async getHeadersForAuthRequest() {
      try {
        let accessToken = await this.getAccessTokenFromCookie().catch(() => null);
  
        if (!accessToken || await this.isAccessTokenExpired()) {
          const refreshToken = await this.getRefreshTokenFromCookie().catch(() => {
            throw new Error('No refresh token found.');
          });
  
          accessToken = await this.refreshAccessToken(refreshToken);
  
          // Update access token cookie
          document.cookie = `accessToken=${accessToken}; Path=/; Secure; SameSite=None`;
        }
  
        return {
          Authorization: `Bearer ${accessToken}`,
        };
      } catch (error) {
        console.error('Error making authenticated request:', error);
        throw error;
      }
    }
  }
  
  export default AuthUtils;
  