import React, { useMemo, useState } from 'react';
import { useTable, useSortBy, useGlobalFilter } from 'react-table';
import '../Styles/Documents.css';
import '../Styles/Tasks.css';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';
import OrderModal from './OrderModal';
import ApiService from '../Services/ApiService';
import { format } from 'date-fns';
import Loader from './Loader';
import { useToast } from './ToastContext';

// Custom global filter function
function globalFilter(rows, ids, query) {
    // Convert the search query to lower case
    const lowercasedQuery = query.toLowerCase();

    return rows.filter(row => {
        // Loop over each row and return true if any column matches the query
        return ids.some(id => {
            // Retrieve the value of the column in the current row
            const rowValue = row.values[id];

            // If it's a date, format it (or use whichever logic applies to your format)
            if (id === 'creationDate' && rowValue) {
                // Format the date to a searchable string if necessary
                const dateStr = new Date(rowValue).toISOString().substring(0, 10);
                return dateStr.toLowerCase().includes(lowercasedQuery);
            }

            // For other types, ensure the value is a string and check if it includes the query
            return String(rowValue).toLowerCase().includes(lowercasedQuery);
        });
    });
}

const Orders = ({ ordersList, clients, skillList }) => {
    
    const [orders, setOrders] = useState(ordersList);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { toast, showToast } = useToast();

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const columns = useMemo(() => [
        { Header: 'Order Id', accessor: 'id' },
        { Header: 'Service', accessor: 'skillName' },
        { Header: 'Created Date', accessor: 'creationDate', Cell: ({ value }) => format(new Date(value), 'dd-MM-yyyy') },
        { Header: 'Price', accessor: 'price', Cell: ({ value }) => <>{value && `₹ ${value}`}</> },
        { Header: 'Client Name', accessor: 'clientName' }
    ], []);

    const onOrderSave = async (orederDetail) => {
        console.log("order save")
        try {
            const response = await ApiService.post('order/createOrder', orederDetail);
            if (response.ok) {
                const orderData = await response.json();
                setOrders([...orders, orderData.data]);
                setIsModalOpen(false);
                showToast('Order saved successful!', 'success');
            } else {
                showToast('Failed to save Order!', 'failure');
            }
        } catch (error) {
            showToast('Failed to save Order', 'failure');
        }

    };

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setGlobalFilter,
    } = useTable({ columns, data: orders, globalFilter }, useGlobalFilter, useSortBy);

    // Render the UI for your table
    return (
        <>
            {loading ?
                <Loader></Loader>
                :
                (<>
                    <div className="add-doc-container">
                        <button className="add-doc-btn" onClick={() => openModal()}>
                            <FaPlus /> Add Order
                        </button>
                        {isModalOpen && (
                            <OrderModal
                                isMapping={false}
                                skills={skillList}
                                orders={orders}
                                clients={clients}
                                onClose={closeModal}
                                onOrderMapSave={null}
                                onOrderSave={onOrderSave}
                            />
                        )}
                    </div>
                    <input
                        type="text"
                        onChange={e => setGlobalFilter(e.target.value)}
                        placeholder="Search all columns"
                        className="searchInput"
                    />
                    <table {...getTableProps()} className="tasksContainer">
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                            className={column.isSorted ? column.isSortedDesc ? 'sort-desc' : 'sort-asc' : ''}
                                        >
                                            {column.render('Header')}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map(row => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return (
                                                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </>)}
        </>
    );
};

export default Orders;
