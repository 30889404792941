import React, { useEffect, useState } from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css'; 
import { Document, Page, pdfjs } from 'react-pdf';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { read, utils } from 'xlsx';
import { AiFillCloseSquare } from "@react-icons/all-files/ai/AiFillCloseSquare";
import {FaCloudDownloadAlt} from '@react-icons/all-files/fa/FaCloudDownloadAlt';
import Loader from './Loader';
import { Select } from '@chakra-ui/react';
import mammoth from 'mammoth';

//import pdfWorker from 'pdfjs-dist/build/pdf.worker.entry';
//pdfjs.GlobalWorkerOptions.workerSrc = pdfWorker;


const DocumentRenderModal = ({fileObj, onClose, onDownload}) =>{
    const [excelContent, setExcelContent] = useState(null);
    const [currentSheet, setCurrentSheet] = useState(null);
    const [docxContent, setDocxContent] = useState(null);
    const [failedToProcessComplexFile, setFailedToProcessComplexFile] = useState(false);

    const handleClose = () =>{
        setExcelContent(null);
        setCurrentSheet(null);
        setDocxContent(null);
        setFailedToProcessComplexFile(false);
        onClose();
    }
    //if (fileObj === null || fileObj === undefined) 
    //    return(
    //        <div className="absolute inset-0 flex justify-center items-center">
    //            <Loader></Loader>
    //        </div>
    //);


    //let iframeObj = null;
    const blob = new Blob([fileObj.blob], { type: fileObj.fileType });
    const createIFrameObj = () =>{
        if (blob.type === 'application/pdf') {
            const _url = URL.createObjectURL(blob);
            const iframeObj = (
                                <div className='w-1/2'>
                                    <iframe src={_url} width="100%" height="100%"></iframe>
                                </div>
                            );
            return(iframeObj);
            /*iframeObj = (
                <div>
                    <Document
                        file={_url}
                        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                    >
                        {Array.from(new Array(numPages), (el, index) => (
                        <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={1.5} />
                        ))}
                    </Document>
                </div>
            );*/
        }
        /*else if (fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || fileType === 'application/vnd.ms-excel') {
            ifameObj = <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileContentURL)}`} width="100%" height="600px"></iframe>;
        }*/
    
        else if (blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || blob.type === 'application/msword') {
            if(failedToProcessComplexFile){
                const iframeObj = (
                    <div className='bg-white h-20 justify-center align-middle rounded-md py-5 px-5 w-1/2'>
                        Failed to show file. Please download and view.
                    </div>
                );
                return(iframeObj);
            }
            const ifameObj = <div className='w-3/4 max-h-screen'>{renderDocxFile()}</div>;
            return(ifameObj);
        }
        else if (blob.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
            || blob.type === 'application/vnd.ms-excel') {
            const iframeObj = <div className='w-3/4 max-h-screen'>{renderExcelTable()}</div>;
            return(iframeObj);
        }
    
        else if (blob.type.startsWith('text/')) {
            const _url = URL.createObjectURL(blob);
            const iframeObj = (<div className='w-3/4 max-h-screen'><iframe src={_url} height="100%" width="100%" ></iframe></div>);
            return(iframeObj);
        }
    
        else if (blob.type.startsWith('image/')) {
            const _url = URL.createObjectURL(blob);
            const iframeObj = (
                        <div className='w-1/2'>
                            <Zoom>
                                <img src={_url} alt={fileObj.fileName} style={{ width: '100%', height: 'auto' }} />
                            </Zoom>
                        </div>)
            return(iframeObj);
        }
    
        else{
            const iframeObj = (
                <div className='bg-white h-20 justify-center align-middle rounded-md py-5 px-5 w-1/2'>
                    File not supported. Please download and view.
                </div>
            );
            return(iframeObj);
        }
    }

    useEffect(() =>{
        const createIFrameObjForExcel = async() =>{
            
            const blob = new Blob([fileObj.blob], { type: fileObj.fileType });
            
            if (blob.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
                || blob.type === 'application/vnd.ms-excel') {
                try{
                    const reader = new FileReader();
                    reader.onload = (e) => {
                    const workbook = read(new Uint8Array(e.target.result), { type: 'array' });
                    const data = {};
    
                    workbook.SheetNames.forEach((sheetName) => {
                        const worksheet = workbook.Sheets[sheetName];
                        const range = utils.decode_range(worksheet['!ref']);
    
    
                        const _data = [];
                        for (let R = range.s.r; R <= range.e.r; ++R){
                            let _row = [];
                            for (let C = range.s.c; C <= range.e.c; ++C) {
                                const cell = worksheet[utils.encode_cell({ r: R, c: C })];
                                _row.push(cell ? cell.v : undefined);
                            }
                            _data.push(_row);
                        }
    
                        data[sheetName] = _data;
                    });
    
                    setExcelContent(data);
                    setCurrentSheet(workbook.SheetNames[0]);
                    };
                    reader.readAsArrayBuffer(blob);
                }
                catch(err){
                    setFailedToProcessComplexFile(true);
                }
            }
            else if(blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || blob.type === 'application/msword'){
                try{
                    const arrayBuffer = await blob.arrayBuffer();
                    const result = await mammoth.convertToHtml({ arrayBuffer });
                    setDocxContent(result.value);
                }
                catch(err){
                    setFailedToProcessComplexFile(true);
                }
            }
        }
        createIFrameObjForExcel();
    },[]);
    

    const handleChange = (event) => {
        setCurrentSheet(event.target.value);
    };

    const renderSheetSelector = () =>{
        if(!excelContent) return null;

        const sheetNames = Object.keys(excelContent);

        return(
            <Select value={currentSheet} onChange={handleChange}>
                {sheetNames.map((option, index) => (
                        <option key={index} value={option}>
                        {option}
                        </option>
                ))}
            </Select>
        );
    }

    const renderDocxFile = () =>{
        if(docxContent === null)
            return (
                <div className="absolute inset-0 flex justify-center items-center">
                    <Loader></Loader>
                </div>
            );
        return(<div dangerouslySetInnerHTML={{ __html: docxContent }} className='bg-white overflow-y-auto h-full p-4 rounded-md'/>);
    }
        

    const renderExcelTable = () => {
        if (excelContent === null) 
            return (
                <div className="absolute inset-0 flex justify-center items-center">
                    <Loader></Loader>
                </div>
            );
        const _data = excelContent[currentSheet];
    
        return (
            <div className="bg-white p-4 rounded-md h-full flex flex-col">
                <div className=' overflow-y-auto h-full mt-4'>
                    <table className="table-auto w-full border-collapse border border-gray-200">
                    <tbody>
                        {_data.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.map((ele, eleIndex) => (
                            <td key={eleIndex} className="border border-black px-2 py-1 text-sm">{ele}</td>
                            ))}
                        </tr>
                        ))}
                    </tbody>
                    </table>
                </div>
                <div className='flex flex-row items-center'>
                    <div className='mt-4 '>
                        <p className=' font-bold mr-2'>Selected</p>
                    </div>
                    <div className='mt-4 '>
                        <p className=' font-bold'>Sheet</p>
                    </div>
                    <div className='mt-4 border-2 border-black rounded-md w-full ml-10'>
                        {renderSheetSelector()}
                    </div>
                </div>
            </div>
        );
    };

    return(
        <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
            <div className='flex flex-row justify-center w-full h-full pt-10 pb-10'>
            {createIFrameObj()}
            <button className=" mx-4 px-4 py-2 bg-black text-white rounded hover:bg-orange-400 h-12" onClick={handleClose}><AiFillCloseSquare className="size-8" /></button>
            <button className=" px-4 py-2 bg-black text-white rounded hover:bg-orange-400 h-12" onClick={() => onDownload(fileObj)}><FaCloudDownloadAlt className="size-8" /></button>
            </div>
        </div>
    );

}


export default DocumentRenderModal;