import React, { useEffect, useState } from 'react';
import { Link, useSearchParams, useNavigate, json } from 'react-router-dom';
import {
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	IconButton,
	MenuItemOption,
	MenuGroup,
	MenuOptionGroup,
	MenuDivider,
} from '@chakra-ui/react'
import { FiFolder } from '@react-icons/all-files/fi/FiFolder';
import { useData } from './DataContext';
import * as Util from '../Utils/Util';
import blankProfilePic from '../images/blank-profile-pic.png';
import { FiMoreVertical } from '@react-icons/all-files/fi/FiMoreVertical';
import { MdModeEdit } from '@react-icons/all-files/md/MdModeEdit';
import { GiHamburgerMenu } from "@react-icons/all-files/gi/GiHamburgerMenu";
import ApiService from '../Services/ApiService';
import { FaCloudUploadAlt } from '@react-icons/all-files/fa/FaCloudUploadAlt';
import { FaFileInvoiceDollar } from "@react-icons/all-files/fa/FaFileInvoiceDollar";
import { FaPlusCircle } from "@react-icons/all-files/fa/FaPlusCircle.js";
import { useToast } from './ToastContext';
import Toast from './ToastComponent';
import { useAuth } from './AuthContext';
import GiveAccessModal from './GiveAccessModal';
import {compressFile} from '../Utils//FileUtil';
import { read, utils } from 'xlsx';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import Loader from './Loader';
import { CgDetailsMore } from '@react-icons/all-files/cg/CgDetailsMore';
import { CgProfile } from "@react-icons/all-files/cg/CgProfile";
import { IoBusiness } from "@react-icons/all-files/io5/IoBusiness";
import { IoKey } from "@react-icons/all-files/io5/IoKey";
import { CgNotes } from "@react-icons/all-files/cg/CgNotes";
import { subscribeToPushNotification } from './UserProfile';
import { useToast as useToastChakra} from '@chakra-ui/react';
import { RiCloseLine } from "@react-icons/all-files/ri/RiCloseLine";

const DBANameModal = ({ userID, contactReqID, dbaName, onClose, refreshClients }) => {
	const [newDBAName, setNewDBAName] = useState('');

	const handleNameChange = (e) => {
		setNewDBAName(e.target.value);
	};

	const handleClose = async () => {
		setNewDBAName('');
		onClose();
	}

	const handleSave = async () => {
		const payLoad = {
			userID: userID,
			contactReqID: contactReqID,
			dbaName: newDBAName
		}

		try {
			const response = await ApiService.post('advisor/CRMUpdateClientDBAName', payLoad);

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			const data = await response.json();
			//console.log(data);
			refreshClients();

		}
		catch (error) {
			console.error("Fetch error: ", error.message);
		}
		handleClose();
	}

	let placeHolderText = '';
	if (dbaName === null || dbaName.trim() === "") placeHolderText = 'Enter a business name';
	else placeHolderText = dbaName;


	return (
		<div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
			<div className="bg-white p-6 rounded-lg shadow-md w-11/12 max-w-lg flex flex-col space-y-4">
				<div className="flex flex-col items-center space-y-2">
					<h2 className="text-lg font-semibold">Update Business Name of the Client</h2>
					<div className="flex items-center mt-2 mb-2 w-full border border-gray-300 rounded-sm focus-within:border-orange-500 focus-within:ring focus-within:ring-orange-200 focus-within:ring-opacity-50">
						<input
							type='text'
							placeholder={placeHolderText}
							value={newDBAName}
							onChange={handleNameChange}
							autoFocus
							className="p-2 w-full focus:outline-none"
						/>
					</div>
				</div>
				<div className="flex justify-end space-x-2">
					<button className="px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-700" onClick={handleSave}>Save</button>
					<button className="px-4 py-2 bg-orange-300 text-white rounded hover:bg-orange-400" onClick={handleClose}>Cancel</button>
				</div>
			</div>
		</div>
	);
}

const PicUploadModal = ({ userID, contactReqID, onClose, updateProfilePics }) => {
	const [selectedFile, setSelectedFile] = useState(null);

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		if (file) {
			const fileData = {
				fileObj: file
			};
			setSelectedFile(fileData);
		} else {
			console.log("No file selected.");
		}
	};

	const handleSave = async () => {
		const formData = new FormData();
		formData.append('userID', userID);
		formData.append('contactReqID', contactReqID);

		if (selectedFile.fileObj) {
			const compressedFile = await compressFile(selectedFile.fileObj);
			formData.append('profileImg', compressedFile);
		} else {
			console.error('No file selected');
			return;
		}

		try {
			const response = await ApiService.postform('advisor/CRMUpdateClientProfilePic', formData);

			if (!response.ok) {
				handleClose();
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			const data = await response.json();
			console.log(data);
			updateProfilePics();
		}
		catch (error) {
			console.error("Fetch error: ", error.message);
		}
		handleClose();
	}

	const handleClose = () => {
		setSelectedFile(null);
		onClose();
	}

	return (
		<div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
			<div className="bg-white p-6 rounded-lg shadow-md w-11/12 max-w-lg flex flex-col space-y-4">
				<div className="flex flex-col items-center space-y-2">
					<label className="inline-flex items-center space-x-2 px-4 py-2 bg-orange-500 text-white rounded cursor-pointer hover:bg-orange-700">
						<FaCloudUploadAlt />
						<span>Select Files</span>
						<input type="file" className="hidden" onChange={handleFileChange} />
					</label>
					{/* Scrollable list container */}
					<div className="w-full max-h-48 overflow-auto">
						{selectedFile !== null &&
							<div className="flex flex-col space-y-1 p-2">
								<span className="text-sm text-gray-800">{selectedFile.fileObj.name}</span>
							</div>
						}
					</div>
				</div>
				<div className="flex justify-end space-x-2">
					<button className="px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-700" onClick={handleSave}>Save</button>
					<button className="px-4 py-2 bg-orange-300 text-white rounded hover:bg-orange-400" onClick={handleClose}>Cancel</button>
				</div>
			</div>
		</div>
	);
}


const AddNewClientModal = ({ onClose, onClickBulkImport, refreshClients }) => {
	const [newFirstName, setNewFirstName] = useState('');
	const [newLastName, setNewLastName] = useState('');
	const [newPhoneNumber, setNewPhoneNumber] = useState('');
	const { toast, showToast } = useToast();

	const handleClose = async () => {
		setNewFirstName('');
		setNewLastName('');
		setNewPhoneNumber('');
		onClose();
	}

	const handleFirstNameChange = (e) => {
		setNewFirstName(e.target.value);
	};

	const handleLastNameChange = (e) => {
		setNewLastName(e.target.value);
	};

	const handlePhoneNumberChange = (e) => {
		setNewPhoneNumber(e.target.value);
	};

	const handleSave = async () => {
		const _firstName = newFirstName.trim();
		const _lastName = newLastName.trim();
		const _phoneNumber = newPhoneNumber.trim();

		if (_firstName.length == 0) {
			showToast("First Name can not be empty", 'failure');
			return;
		}
		if (_lastName.length == 0) {
			showToast("Last Name can not be empty", 'failure');
			return;
		}
		if (_phoneNumber.length != 10) {
			showToast("Please provide a 10 digit phone number", 'failure');
			return;
		}

		try {
			const payLoad = {
				firstName: _firstName,
				lastName: _lastName,
				phoneNumber: _phoneNumber
			}
			const response = await ApiService.post('advisor/createClientFromCRM', payLoad);

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			const data = await response.json();
			console.log(data);
			refreshClients();

		}
		catch (error) {
			console.error("Fetch error: ", error.message);
		}
		handleClose();

	}

	return (
		<div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
			<div className="bg-white p-6 rounded-lg shadow-md w-11/12 max-w-lg flex flex-col space-y-4">
				<div className="flex flex-col items-center space-y-2">
					<h2 className="text-lg font-semibold">Create new client</h2>
					<div className="flex items-center mt-2 mb-2 w-full border border-gray-300 rounded-sm focus-within:border-orange-500 focus-within:ring focus-within:ring-orange-200 focus-within:ring-opacity-50">
						<input
							type='text'
							placeholder={'Enter first name'}
							value={newFirstName}
							onChange={handleFirstNameChange}
							autoFocus
							className="p-2 w-full focus:outline-none"
						/>
					</div>
					<div className="flex items-center mt-2 mb-2 w-full border border-gray-300 rounded-sm focus-within:border-orange-500 focus-within:ring focus-within:ring-orange-200 focus-within:ring-opacity-50">
						<input
							type='text'
							placeholder={'Enter last name'}
							value={newLastName}
							onChange={handleLastNameChange}
							className="p-2 w-full focus:outline-none"
						/>
					</div>
					<div className="flex items-center mt-2 mb-2 w-full border border-gray-300 rounded-sm focus-within:border-orange-500 focus-within:ring focus-within:ring-orange-200 focus-within:ring-opacity-50">
						<input
							type='number'
							placeholder={'Enter 10 digit phone number'}
							value={newPhoneNumber}
							onChange={handlePhoneNumberChange}
							className="p-2 w-full focus:outline-none"
						/>
					</div>
					<Toast message={toast.message} type={toast.type} />
				</div>
				<div className=' flex justify-between'>
					<div className=''>
						<button className="px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-700 " onClick={onClickBulkImport}>Bulk Import</button>
					</div>
					<div className="flex justify-end space-x-2">
						<button className="px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-700" onClick={handleSave}>Save</button>
						<button className="px-4 py-2 bg-orange-300 text-white rounded hover:bg-orange-400" onClick={handleClose}>Cancel</button>
					</div>
				</div>
			</div>
		</div>
	);
}

const BultImportClientsModal = ({onClose, refreshClients}) =>{
	const [selectedFile, setSelectedFile] = useState(null);
	const { toast, showToast } = useToast();
	const [excelData, setExcelData] = useState([]);
	const [renderData, setRenderData] = useState([]);
	const [isImporting, setIsImporting] = useState(false);
	const listOfEligibleCols = ['first name', 'last name', 'phone number', 'aadhaar id', 'voter id', 'personal pan'];
	const [isImportComplete, setIsImportComplete] = useState(false);

	const handleCancel = () =>{
		setSelectedFile(null);
		setExcelData([]);
		setRenderData([]);
		setIsImporting(false);
		setIsImportComplete(false);
		onClose();
	}

	const checkInputData = (contentArr) =>{
		for(let row of contentArr){
			if (!(row.hasOwnProperty('First Name') && row.hasOwnProperty('Last Name') && row.hasOwnProperty('Phone Number')))
				return false;
		}
		return true;
	}

	const createListToRender = contentArr =>{
		const _listToRender = [];
		for(let row of contentArr){
			const _name = row.hasOwnProperty('Last Name') ? (row['First Name'] + " " + row['Last Name']) : row['First Name'];
			_listToRender.push({
				name : _name,
				status : 0,
				errMsg : ''
			});
		}
		setRenderData(_listToRender);
	}

	const handleFileChange = (event) => {
		const _file = event.target.files[0];
		if(_file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || _file.type === 'application/vnd.ms-excel' || _file.type === 'text/csv'){
			setSelectedFile(_file);
			const reader = new FileReader();
			reader.onload = (e) => {
				const data = new Uint8Array(e.target.result);
				const workbook = read(data, { type: 'array' });
				const sheetName = workbook.SheetNames[0];
				const worksheet = workbook.Sheets[sheetName];
				const jsonContent = utils.sheet_to_json(worksheet);
				if(!checkInputData(jsonContent)){
					showToast('File template error. Please use proper format.', 'failure');
					setSelectedFile(null);
					return;
				}
				createListToRender(jsonContent);
				console.log(jsonContent);
				setExcelData(jsonContent);
			};
			reader.readAsArrayBuffer(_file);
		}
		else{
			showToast('Please select an excel file or csv file.', 'failure');
		}
	};

	const statusToStr = (status) =>{
		if (status === 0)
			return 'Yet to process'
		else if(status === 1)
			return 'Processing...';
		else if(status === 2)
			return 'Success';
		else if(status === 3)
			return 'Error';

		return '';
	}

	const renderListOfClients = () =>{
		if (renderData.length === 0) return null;

		return(
			<div className="overflow-y-auto h-96 border-2 rounded-md border-black mt-4">
				<table className="min-w-full border-collapse">
					<tbody>
						{renderData.map((item, index) => (
						<tr key={index} className="">
							<td style={{ width: '300px' }} className=" px-4 py-2 border-0 text-lg">{item.name}</td>
							<td style={{ width: '250px' }} className=" px-4 py-2 border-0">{statusToStr(item.status)}</td>
							<td style={{ width: '350px', maxWidth:'350px' }} className=" px-4 py-2 border-0 text-red-500">{item.errMsg}</td>
						</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	};

	const setProperty = (obj, propertyName, destObj, destProperty) =>{
		if(obj.hasOwnProperty(propertyName) && obj[propertyName] !== null){
			let _val = String(obj[propertyName]);
			_val = _val.trim();
			if(_val.length > 0)
				destObj[destProperty] = obj[propertyName];
		}
	}

	const delay = (ms) => {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	const handleSave = async() =>{
		if(excelData.length === 0) return;

		setIsImporting(true);
		let counter = 0;
		for(let row of excelData){
			
			const _curr_renderData = renderData;
			renderData[counter].status = 1;
			setRenderData([..._curr_renderData]);
			await delay(500);
			try{
				const payLoad = {
					firstName: row['First Name'],
					lastName: row['Last Name'],
					phoneNumber: String(row['Phone Number'])
				}

				setProperty(row, 'Aadhaar ID', payLoad, 'aadhaarid');
				setProperty(row, 'Voter ID', payLoad, 'voterid');
				setProperty(row, 'Personal PAN', payLoad, 'personalpan');
				setProperty(row, 'Personal Description', payLoad, 'personaldescription');
				setProperty(row, 'Company PAN', payLoad, 'companypan');
				setProperty(row, 'Company Name', payLoad, 'companyname');
				setProperty(row, 'Company Description', payLoad, 'companydescription');

				const response = await ApiService.post('advisor/createClientFromCRM', payLoad);

				if (response.ok) {
					const _curr_renderData = renderData;
					renderData[counter].status = 2;
					setRenderData([..._curr_renderData]);
				}
				else{
					const data = await response.json();
					console.log(data);
					const _curr_renderData = renderData;
					renderData[counter].status = 3;
					renderData[counter].errMsg = data.error;
					setRenderData([..._curr_renderData]);
				}
				counter++;
			}
			catch (error) {
				const _curr_renderData = renderData;
				renderData[counter].status = 3;
				renderData[counter].errMsg = 'Unable to create client.';
				setRenderData([..._curr_renderData]);
				console.error("Fetch error: ", error.message);
			} 
		}
		setIsImporting(false);
		setIsImportComplete(true);
		refreshClients();
	}

	if(selectedFile === null)
		return(
			<div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
			<div className="bg-white p-6 rounded-lg shadow-md flex flex-col space-y-4 items-center w-2/5">
			  <div className="flex flex-col items-center space-y-2 w-96">
				<h2 className="text-lg font-semibold">Import clients</h2>
			  </div>
			  
			<div className="flex justify-center">
				<label>
				<span className="px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-700">
					Select File
				</span>
				<input type="file" className="hidden" onChange={handleFileChange} />
				</label>
			</div>

			  <Toast message={toast.message} type={toast.type} />
			  <div className="flex justify-between items-center w-full mt-auto">
				<div className="p-2 rounded">
				  <a 
					href="https://docs.google.com/spreadsheets/d/1qexT115qwO5jG2r6bTyJnzOBqsKLsEGy/edit?usp=sharing&ouid=114713996971793623516&rtpof=true&sd=true" 
					target="_blank" 
					rel="noopener noreferrer" 
					className="text-blue-500 hover:text-blue-700"
				  >
					Download Example template
				  </a>
				</div>
				<button className="px-4 py-2 bg-orange-300 text-white rounded hover:bg-orange-400" onClick={handleCancel}>
				  Cancel
				</button>
			  </div>
			</div>
		  </div>
		);

	else
		return(
			<div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
				<div className="bg-white p-6 rounded-lg shadow-md flex flex-col space-y-4 items-center">
				<div className="flex flex-col items-center space-y-2 w-96">
					<h2 className="text-lg font-semibold">Import clients</h2>
				</div>

				<div className="mt-4">
					<p className=' text-lg'>Selected File: {selectedFile.name}</p>
					{renderListOfClients()}
				</div>

				{isImporting &&
				<div><h2 className="text-md font-semibold">Please do not close or refresh the page. It will take some time.</h2></div>}

				{!isImporting && !isImportComplete &&
				<div className="flex justify-end space-x-2 w-full">
					<button className="px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-700" onClick={handleSave}>Start Import</button>
					<button className="px-4 py-2 bg-orange-300 text-white rounded hover:bg-orange-400" onClick={handleCancel} >Cancel</button>
				</div>}

				{!isImporting && isImportComplete &&
				<div className="flex justify-end space-x-2 w-full">
					<button className="px-4 py-2 bg-orange-300 text-white rounded hover:bg-orange-400" onClick={handleCancel} >Cancel</button>
				</div>}
				</div>
			</div>
		);
}

const ClientDetailEditSubModal = ({item, onclose, fetchClientDetail, refreshClients, updateNameForThisView}) =>{
	const [currvalue, setCurrValue] = useState(item.value);
	const [isUpdating, setIsUpdating] = useState(false);

	if (item === undefined || item === null ) return;

	const handleOnClose = () =>{
		setCurrValue('');
		onclose();
	}

	const handleChange = (e) =>{
		setCurrValue(e.target.value);
	}

	const handleOnSave = async() =>{
		if (currvalue.trim().length === 0) return;
		if (isUpdating) return;

		setIsUpdating(true);
		try{
			const payload = {
				userid : item.userid
			};
			payload[item.property] = currvalue.trim();
			const response = await ApiService.post('advisor/createOrUpdateClientDetail', payload);

			if (response.ok) {
				fetchClientDetail();
				handleOnClose();
				if(item.property === 'firstName'){
					updateNameForThisView(payload[item.property], '');
					refreshClients();
				}
				else if(item.property === 'lastName'){
					updateNameForThisView('',payload[item.property]);
					refreshClients();
				}
			}
			setIsUpdating(false);
		}
		catch(error){
			//error
			setIsUpdating(false);
		}

	}

	return(
		<div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
			<div className="bg-white p-6 rounded-lg shadow-md flex flex-col space-y-4 items-center w-1/3">
			<div className="flex flex-col items-center space-y-2">
				<h2 className="text-lg font-semibold">{'Edit ' + item.label}</h2>
			</div>
			<div className="flex items-center mt-2 mb-2 w-full border border-gray-300 rounded-sm focus-within:border-orange-500 focus-within:ring focus-within:ring-orange-200 focus-within:ring-opacity-50">
				{item.label !=='Extra Details' &&
					<input
					type="text"
					value={currvalue}
					onChange={handleChange}
					autoFocus
					className="border rounded px-2 py-1 w-full"
				/>}
				{item.label ==='Extra Details' &&
					<textarea
					placeholder="Enter notes"
					value={currvalue}
					onChange={handleChange}
					autoFocus
					className="px-2 py-1 w-full focus:outline-none resize-y h-24"
				/>}
			</div>
			<div className="flex justify-end space-x-2 w-full">
                    <button className="px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-700" onClick={handleOnSave}>{isUpdating ? 'Saving...' : 'Save'}</button>
                    <button className="px-4 py-2 bg-orange-300 text-white rounded hover:bg-orange-400" onClick={handleOnClose}>Cancel</button>
			</div>
			</div>
		</div>
	
	);
}

const ClientDetailModal = ({onClose, selectedClient, refreshClients}) =>{
	const [clientDetail, setClientDetail] = useState(null);
	const [isFetchingData, setIsFetchingData] = useState(true);
	const [isEditSubModalVisible, setIsEditSubModalVisible] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const _fullName = Util.getFullName(selectedClient.firstName, selectedClient.lastName);
	const [clientFirstName, setClientFirstName] = useState(selectedClient.firstName);//these 2 are only used to update in case of name change for this view
	const [clientLastName, setClientLastName] = useState(selectedClient.lastName);


	const handleClose = () =>{
		setClientDetail(null);
		onClose();
	}

	const updateNameForThisView = (fName, lName) =>{
		if (fName.length > 0)
			setClientFirstName(fName);
		if (lName.length > 0)
			setClientLastName(lName);
	}

	const fetchClientDetail = async() =>{
		try {
			const payLoad = {
				userid : selectedClient.userId
			};
			const response = await ApiService.post('advisor/getClientDetail', payLoad);

			if (response.status === 200) {
				const data = await response.json();
				setClientDetail(data.data);
			}
			else if (response.status === 404) {
				setClientDetail({});
			}
			else {
				//throw new Error(`HTTP error! status: ${response.status}`);
				handleClose();
			}
			setIsFetchingData(false);
		}
		catch (error) {
			console.error("Fetch error: ", error.message);
			handleClose();
		}
	}

	useEffect(() => {
		fetchClientDetail();
	}, []);

	const DetailItem = ({ label, value, property }) => {
		const handleOnClickEdit = () =>{
			setIsEditSubModalVisible(true);
			setSelectedItem({
				label:label,
				value:value,
				property:property,
				userid : selectedClient.userId
			});
		}

		return (
		  <div className="flex items-center ml-4 mt-2 mb-2">
			  <p className="text-lg font-medium text-black mr-4 whitespace-nowrap">{label}: </p>
			  <p className="text-lg text-black max-w-full break-words">{value==undefined ? '': value}</p>
			  <MdModeEdit className='ml-4 cursor-pointer' color='#fe5f00' onClick={()=>handleOnClickEdit()}/>
		  </div>
		);
	};

	return(
		<div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
			<div className="bg-white p-6 rounded-lg shadow-md flex flex-col space-y-4 items-center w-3/4">
			<div className="flex flex-col items-center space-y-2">
				<h2 className="text-lg font-semibold">{_fullName}</h2>
			</div>
			{isFetchingData &&
			<div className="flex justify-center items-center z-60 h-96">
				<Loader></Loader>
			</div>
			}
			{clientDetail !==null &&
				<div className="w-full">
			<Tabs variant='enclosed'>
				<TabList>
					<Tab _selected={{ color: 'white', bg: 'orange.500' }}>Personal</Tab>
					<Tab _selected={{ color: 'white', bg: 'orange.500' }}>Compnay</Tab>
				</TabList>
				<TabPanels>
					<TabPanel>
					<div className="overflow-y-auto h-96 w-full border-2 rounded-md border-black">
						<DetailItem label="First Name" value={clientFirstName} property='firstName'/>
						<DetailItem label="Last Name" value={clientLastName} property='lastName'/>
						<DetailItem label="Aadhaar Number" value={clientDetail.aadhaarid} property='aadhaarid'/>
						<DetailItem label="Voter Number" value={clientDetail.voterid} property='voterid'/>
						<DetailItem label="PAN Number" value={clientDetail.personalpan} property='personalpan'/>
						<DetailItem label="Extra Details" value={clientDetail.personaldescription} property='personaldescription'/>
						

					</div>
					</TabPanel>
					<TabPanel>
					<div className="overflow-y-auto h-96 w-full border-2 rounded-md border-black">
						<DetailItem label="Company Name" value={clientDetail.companyname} property='companyname'/>
						<DetailItem label="PAN Number" value={clientDetail.companypan} property='companypan'/>
						<DetailItem label="Extra Details" value={clientDetail.companydescription} property='companydescription'/>
					</div>
					</TabPanel>
				</TabPanels>
			</Tabs>
			</div>}
			{isEditSubModalVisible &&
					<ClientDetailEditSubModal
					item={selectedItem}
					onclose={() => setIsEditSubModalVisible(false)}
					fetchClientDetail={fetchClientDetail}
					refreshClients={refreshClients}
					updateNameForThisView={updateNameForThisView}/>
			}
			<div className="flex justify-end w-full">
				<button className="px-4 py-2 bg-orange-300 text-white rounded hover:bg-orange-400" onClick={handleClose}>Close</button>
			</div>
			</div>
		</div>
		)

}

const Clients = () => {
	const navigate = useNavigate();
	const { clients, setCurrentDocClient, refreshClients } = useData();
	const [clientProfilePics, setClientProfilePics] = useState([]);
	const [selectedClient, setSelectedClient] = useState(null);
	const [isPicUploadModalVisible, setIsPicUploadModalVisible] = useState(false);
	const [isDBAModalVisible, setIsDBAModalVisible] = useState(false);
	const [isClientDetailModalVisible, setIsClientDetailModalVisible] = useState(false);
	const [isAddClientModalVisible, setAddClientModalVisible] = useState(false);
	const [isBulkClientImportModalVisible, setIsBulkClientImportModalVisible] = useState(false);
	const { isAdmin } = useAuth();
	const [isGiveAccessModalOpen, setIsGiveAccessModalOpen] = useState(false);
	const { toast, showToast } = useToast();
	const [ showNotificationEnableToast, setShowNotificationToast ] = useState(false);
	const toastChakra = useToastChakra();
	const toastIdRef = React.useRef();

	const notificationIsGranted = () =>{
		return Notification.permission === 'granted';
	}

	const handleOpenGiveAccessModal = () => {
		setIsGiveAccessModalOpen(true);
	};

	const handleCloseGiveAccessModal = () => {
		setIsGiveAccessModalOpen(false);
	};

	const handleSaveAccessModal = async (giveAccessObj) => {
		try {
            const response = await ApiService.post('advisor/givePermission', giveAccessObj);
            if (response.ok) {
                showToast('Access granted successful!', 'success');
            } else {
                showToast('Failed to grant acess!', 'failure');
            }
        } catch (error) {
            showToast('Failed to grant acess', 'failure');
        }

	};

	const updateProfilePics = async () => {
		if(clientProfilePics === undefined) return;
		
		const _profilePics = clientProfilePics;
		const payload = [];
		for (let _client of clients) {
			payload.push({
				userID: _client.userId,
				contactReqID: _client.id
			});
		}
		const response = await getProfilePicURL(payload);
		for(let _res of response){
			const imageKey = `${_res.userID}_${_res.contactReqID}`;
			_profilePics.push({
				imageKey : imageKey,
				imageURL : _res.profilePicURL
			});
		}
		setClientProfilePics([..._profilePics]);
	}

	useEffect(() => {
		updateProfilePics();

		if(!notificationIsGranted()){
			showNotificationToast();
		}
		
	}, []);

	const showNotificationToast = () =>{

		const onHandleClick = () =>{
			if (toastIdRef.current) {
				toastChakra.close(toastIdRef.current)
			}
		}

		toastIdRef.current = toastChakra({
			title: `bottom-right toast`,
			position: 'bottom-right',
			duration: 5000,
			isClosable: true,
			render: () => {
			return(
				<div className='p-4 border-2 border-orange-400 rounded-md absolute right-4 bottom-4 bg-white w-full'>
					<RiCloseLine className=' absolute right-2 top-2 cursor-pointer' onClick={onHandleClick}/>
					<p className=' font-bold text-md pb-2 text-center'>Get notifications on changes</p>
					<div className='rounded bg-orange-400 justify-center text-center p-2 cursor-pointer' onClick={() =>{
						onHandleClick();
						subscribeToPushNotification();}
						}>
						<p className=' font-bold'>Enable Notification</p>
					</div>
				</div>
				)
			}
		});
	}

	const getProfilePicURL = async (payLoad) => {
		try {
			const response = await ApiService.post('advisor/CRMUGetClientPic', payLoad);

			if (response.status === 200) {
				const data = await response.json();
				return data.data;
			}
			else if (response.status === 404) {
				return null;
			}
			else {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
		}
		catch (error) {
			console.error("Fetch error: ", error.message);
		}
	}

	const OptionBtn = ({ client }) => {
		return (
			<Menu>
				<MenuButton
					as={IconButton}
					aria-label='Options'
					icon={<GiHamburgerMenu />}
					variant='outline'
				/>
				<MenuList className="bg-white shadow-xl rounded-lg">
					<MenuItem
						icon={<CgProfile className="h-5 w-5" color='#fe5f00' />}
						className="py-2 px-2"
						sx={{
							borderBottom: '1px solid #808080',
						}}
						_hover={{
							bg: 'gray.100',
							roundedTop: 10
						}}
						onClick={() => { setSelectedClient(client); setIsPicUploadModalVisible(true); }}>
						Upload Profile Image
					</MenuItem>
					<MenuItem
						icon={<IoBusiness className="h-5 w-5" color='#fe5f00' />}
						className="py-2 px-2"
						sx={{
							borderBottom: '1px solid #808080',
						}}
						_hover={{
							bg: 'gray.100',
						}}
						onClick={() => { setSelectedClient(client); setIsDBAModalVisible(true); }}>
						Edit Business Name
					</MenuItem>
					<MenuItem
						icon={<CgNotes className="h-5 w-5" color='#fe5f00' />}
						className="py-2 px-2"
						sx={{
							borderBottom: '1px solid #808080',
						}}
						_hover={{
							bg: 'gray.100',
						}}
						onClick={() => { setSelectedClient(client); setIsClientDetailModalVisible(true); }}>
						Client Details
					</MenuItem>
					{isAdmin
						?
						<MenuItem
							icon={<IoKey className="h-5 w-5" color='#fe5f00' />}
							className="py-2 px-2"
							_hover={{
								bg: 'gray.100',
								roundedBottom: 10
							}}
							onClick={() => { setSelectedClient(client); handleOpenGiveAccessModal(); }}>
							Give Access
						</MenuItem>
						: null}
				</MenuList>
			</Menu>
		);
	}

	const navigateToDocuments = (client, _fullName) => {
		const path = `/clients/${encodeURIComponent(_fullName.replace(/\s/g, "-"))}/documents`;
		console.log('selected client', client);
		setCurrentDocClient(client);
		navigate(path, { state: { currentClient: client } });
	};

	const navigateToOrders = (client) => {
		console.log('selected client', client);
		navigate(`/clients/client/orders?clientId=${client.userId}`);
	};

	const onPressAddNewClient = () => {
		setAddClientModalVisible(true);
	}

	const onClickBulkImport = () =>{
		setAddClientModalVisible(false);
		setIsBulkClientImportModalVisible(true);
	}

	return (
		<div className="flex flex-col h-screen">
			{isGiveAccessModalOpen ? <GiveAccessModal isOpen={isGiveAccessModalOpen} onClose={handleCloseGiveAccessModal} clientId={selectedClient.userId} onSave={handleSaveAccessModal}/> : null}
			<div className="flex justify-end space-x-1 mb-2.5 w-full">
				<div>
					<button className="flex items-center px-2.5 py-2.5 bg-orange-500 text-white text-sm leading-none tracking-wide rounded cursor-pointer hover:bg-orange-700" onClick={onPressAddNewClient}>
						<FaPlusCircle className="mr-1.5" /> Add new client
					</button>
				</div>
				{isAddClientModalVisible &&
					<AddNewClientModal
						onClose={() => setAddClientModalVisible(false)} 
						onClickBulkImport={onClickBulkImport}
						refreshClients={refreshClients}/>
				}
				{isBulkClientImportModalVisible &&
				<BultImportClientsModal
				onClose={() => setIsBulkClientImportModalVisible(false)}
				refreshClients={refreshClients}/>}

				{isClientDetailModalVisible && selectedClient !== null &&
				<ClientDetailModal
				onClose={() => setIsClientDetailModalVisible(false)}
				selectedClient={selectedClient}
				refreshClients={refreshClients}/>}
			</div>
			<div className="grid grid-cols-4 min-w-min max-w-full gap-5 p-5 justify-center">
				{isPicUploadModalVisible && selectedClient !== null &&
					<PicUploadModal
						userID={selectedClient.userId}
						contactReqID={selectedClient.id}
						onClose={() => setIsPicUploadModalVisible(false)} 
						updateProfilePics={updateProfilePics}/>
				}
				{isDBAModalVisible && selectedClient !== null &&
					<DBANameModal
						userID={selectedClient.userId}
						contactReqID={selectedClient.id}
						dbaName={selectedClient.dbaName}
						onClose={() => setIsDBAModalVisible(false)}
						refreshClients={refreshClients} />
				}
				{clients.map(client => {
					const _fullName = Util.getFullName(client.firstName, client.lastName);
					const _imgKey = `${client.userId}_${client.id}`;
					const _imgItem = clientProfilePics === undefined ? null : clientProfilePics.find(item => item.imageKey === _imgKey);
					const _imageURL = (_imgItem === undefined || _imgItem === null) ? null : _imgItem.imageURL === null ? null : _imgItem.imageURL;
					return (
						<div key={client.userId} className="bg-white shadow-md rounded-lg overflow-hidden flex flex-col items-center p-4 max-w-sm border-orange-400 border relative">
							<div className="absolute top-0 right-0 m-2 cursor-pointer">
								<OptionBtn
									client={client} />
							</div>
							<img src={(_imageURL === undefined || _imageURL === null) ? blankProfilePic : _imgItem.imageURL} alt="Client" className="w-24 h-24 rounded-full object-cover" />
							<div className="text-center mt-2.5">
								<h3 className="mt-2 mb-1">{_fullName}</h3>
								<p className="m-0">{client.dbaName ? client.dbaName : '_'}</p>
								<div className="flex flex-col items-center justify-center">
									<button onClick={() => navigateToDocuments(client, _fullName)} className="flex items-center mt-2.5 text-orange-500 no-underline hover:underline">
										<FiFolder size={20} /> Documents
									</button>
									<button onClick={() => navigateToOrders(client)} className="flex items-center mt-2.5 text-orange-500 no-underline hover:underline">
										<FaFileInvoiceDollar size={20} /> Orders
									</button>
								</div>

							</div>
						</div>
					)
				})}
			</div>
		</div>

	);
};


export default Clients;
