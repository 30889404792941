import React, { useState } from 'react';
import '../Styles/TaskDetailComponent.css';
import DetailsView from './DetailsView';
import TaskAttachments from './TaskAttachments';
import ApiService from '../Services/ApiService';
import { ToastProvider, useToast } from './ToastContext';

const TaskDetailComponent = () => {
  const [activeTab, setActiveTab] = useState('details');
  const [saveRequested, setSaveRequested] = useState(false);
  const { toast, showToast } = useToast();

  const handleSave = async (taskDetails) => {
    try {
      let { newDiscussion, ...taskDetailWithoutDiscussion } = taskDetails;
      let response = undefined;
      if(taskDetailWithoutDiscussion){
        response = await ApiService.put('advisor/updateTask', taskDetailWithoutDiscussion);
      }

      if (!response.ok) {
        showToast('Failed to save task!', 'failure');
        throw new Error('Failed to save task');
      }

      if(taskDetails.newDiscussion){
        const discussionPayload = {
          "message": taskDetails.newDiscussion,
          "taskId": taskDetails.taskId
        }
        try {
          const discussionresp = await ApiService.post('advisor/addDiscussion', discussionPayload);
          if (!discussionresp.ok) {
            showToast('Failed to save discussion!', 'failure');
            throw new Error('Failed to save task discussion');
          }
          showToast('Operation successful!', 'success');
          console.log('Task discussion saved successfully');
        } catch (error) {
          showToast('Operation failed!', 'failure');
          console.log('Task discussion failed to save.');
        }
        
      }
      console.log('Task saved successfully');
    } catch (error) {
      console.error('Error saving task:', error);
    }
  };

  return (
    <div className="task-detail-container">
      <div className="action-buttons">
        <button className="save-btn" onClick={() => setSaveRequested(true)} disabled={saveRequested}>Save</button>
        <button className="cancel-btn">Cancel</button>
      </div>
      <div className="tabs">
        <div className={`tab ${activeTab === 'details' ? 'active' : ''}`} onClick={() => setActiveTab('details')}>Details</div>
        <div className={`tab ${activeTab === 'attachments' ? 'active' : ''}`} onClick={() => setActiveTab('attachments')}>Attachments</div>
      </div>
      {activeTab === 'details' && <DetailsView onSave={handleSave} saveRequested={saveRequested} setSaveRequested={setSaveRequested} />}
      {activeTab === 'attachments' && <TaskAttachments />}
    </div>
  );
};

export default TaskDetailComponent;