import React, { createContext, useState, useContext, useEffect } from 'react';
import ApiService from '../Services/ApiService';
import AuthUtils from '../Utils/AuthUtils';
import Loader from './Loader';

const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const [clients, setClients] = useState([]);
    const [advisors, setAdvisors] = useState([]);
    const [skillList, setSkillList] = useState([]);
    const [orderList, setOrderList] = useState([]);
    const [taskOrderList, setTaskOrderList] = useState([]);
    const [logedInAdvisorId, setLogedInAdvisorId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currentDocClient, setCurrentDocClient] = useState(null); //holds the client for whom the current documents are open

    const fetchClients = async () => {
        try {
            const response = await ApiService.get("contact/advisor");
            if (response.ok) {
                const clientsData = await response.json();
                clientsData.data.acceptedReq.sort((a, b) => {
                    if (a.firstName < b.firstName) {
                        return -1;
                    }
                    if (a.firstName > b.firstName) {
                        return 1;
                    }
                    return 0;
                });
                return clientsData.data.acceptedReq;
            } else {
                throw new Error();
            }
        } catch (error) {
            console.error('Failed to fetch clients:', error);
            return [];
        }
    };

    const refreshClients = async () => {
        const _clients = await fetchClients();
        setClients(_clients);
    }

    const fetchAdvisors = async () => {
        try {
            const response = await ApiService.get("advisor/allEmployees");
            if (response.ok) {
                const advisorsData = await response.json();
                return advisorsData.data;
            } else {
                throw new Error();
            }
        } catch (error) {
            console.error('Failed to fetch advisors:', error);
            return [];
        }
    };

    const refreshAdvisors = async () => {
        const _advisors = await fetchAdvisors();
        setAdvisors(_advisors);
    }

    const fetchOrders = async () => {
        try {
            const response = await ApiService.get("order/allAdvisorOrders?orderBy=creationDateDesc");
            if (response.ok) {
                const advisorsData = await response.json();
                return [...advisorsData.data.pendingReq, ...advisorsData.data.acceptedReq];
            } else {
                throw new Error();
            }
        } catch (error) {
            console.error('Failed to fetch orders:', error);
            return [];
        }
    };

    const fetchAdvisorSkills = async () => {
        try {
            const response = await ApiService.get("advisor/personalDetail");
            if (response.ok) {
                const advisorsData = await response.json();
                return advisorsData.data.skillSet.map(item => ({
                    id: item.skillId,
                    skillName: item.skillName,
                    skillPrice: item.skillCharge
                }));
            } else {
                throw new Error();
            }
        } catch (error) {
            console.error('Failed to fetch advisor skills:', error);
            return [];
        }
    };

    const refreshAdvisorSkills = async() =>{
        const _skills = await fetchAdvisorSkills();
        setSkillList(_skills);
    }

    const fetchLogedInAdvisorId = async () => {
        try {
            const advisorId = await AuthUtils.getLogedInAdvisorId();
            return advisorId;
        } catch (error) {
            console.error('Failed to fetch logged in advisor ID:', error);
            return null;
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            const [clients, advisors, skills, orders, advisorId] = await Promise.all([
                fetchClients(),
                fetchAdvisors(),
                fetchAdvisorSkills(),
                fetchOrders(),
                fetchLogedInAdvisorId(),
            ]);

            setClients(clients);
            setAdvisors(advisors);
            setSkillList(skills);
            setOrderList(orders);
            setLogedInAdvisorId(advisorId);

            if (!clients.length && !advisors.length && !skills.length && !orders.length && !advisorId) {
                alert('Failed to load data, please refresh the page.');
            }

            setLoading(false);
        };

        fetchData();
    }, []);

    if (loading) {
        return <Loader></Loader>; // Optionally, render a loading indicator
    }

    return (
        <DataContext.Provider value={{ clients, advisors, skillList, orderList, logedInAdvisorId, setCurrentDocClient, refreshClients, refreshAdvisorSkills }}>
            {children}
        </DataContext.Provider>
    );
};

export const useData = () => useContext(DataContext);
