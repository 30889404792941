import React from 'react';
import '../Styles/Toast.css'; // This is where you'll define your CSS styles

export const Toast = ({ message, type }) => {
  if (!message) return null; // Don't render the toast if there's no message

  return (
    <div className={`toast ${type}`}>
      {message}
    </div>
  );
};

export default Toast;