import React, { createContext, useContext, useState, useEffect } from 'react';
import AuthUtils from '../Utils/AuthUtils';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const login = async () => {
    setIsLoggedIn(true);
    const isAdmin = await AuthUtils.getIsAdmin();
    setIsAdmin(isAdmin);
  };
  const logout = () => {
    setIsLoggedIn(false);
    setIsAdmin(false);
  };

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const accessToken = await AuthUtils.getHeadersForAuthRequest();
        const isAdmin = await AuthUtils.getIsAdmin();
        setIsAdmin(isAdmin);
        setIsLoggedIn(accessToken ? true : false);
      } catch (error) {
        console.error('Error checking auth status:', error);
        setIsLoggedIn(false);
      }
    };

    checkAuthStatus();
  }, []);

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout, isAdmin }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
