import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

const NewEmployeeModal = ({ isOpen, onClose, onSave }) => {
  const [formData, setFormData] = useState({
    phoneNumber: '',
    firstName: '',
    lastName: '',
    accessLevel: null,
    tncChecked: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSelectChange = (value) => {
    setFormData({
      ...formData,
      accessLevel: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create New Employee</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <FormControl id="phoneNumber" isRequired>
              <FormLabel>Phone Number</FormLabel>
              <Input
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl id="firstName" isRequired mt={4}>
              <FormLabel>First Name</FormLabel>
              <Input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl id="lastName" isRequired mt={4}>
              <FormLabel>Last Name</FormLabel>
              <Input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl id="accessLevel" isRequired mt={4}>
              <FormLabel>Access level</FormLabel>
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  color="#fe5f00"
                  borderColor="#fe5f00"
                  _hover={{ borderColor: '#fe5f00' }}
                  _focus={{ borderColor: '#fe5f00', boxShadow: '0 0 0 1px #fe5f00' }}
                >
                  {formData.accessLevel === null ? 'Select' : formData.accessLevel === 1 ? 'Admin' : formData.accessLevel === 2 ? 'Manager': 'Employee'}
                </MenuButton>
                <MenuList>
                  <MenuItem
                    onClick={() => handleSelectChange(1)}
                    _hover={{ backgroundColor: '#fe5f00', color: 'white' }}
                  >
                    Admin
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleSelectChange(2)}
                    _hover={{ backgroundColor: '#fe5f00', color: 'white' }}
                  >
                    Manager
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleSelectChange(3)}
                    _hover={{ backgroundColor: '#fe5f00', color: 'white' }}
                  >
                    Employee
                  </MenuItem>
                </MenuList>
              </Menu>
            </FormControl>
            <FormControl id="tncChecked" mt={4}>
              <Checkbox
                name="tncChecked"
                isChecked={formData.tncChecked}
                onChange={handleChange}
              >
                Accept Terms and Conditions
              </Checkbox>
            </FormControl>
          </form>
        </ModalBody>
        <ModalFooter>
          <Box ml="auto">
            <Button type="submit" className="btn-save" mr={3} onClick={handleSubmit}>
              Save
            </Button>
            <Button className="btn-close" onClick={onClose}>Cancel</Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NewEmployeeModal;