import React, { useMemo, useState, useEffect } from 'react';
import { useTable, useSortBy, useGlobalFilter } from 'react-table';
import { Spinner, Box, Flex } from '@chakra-ui/react';
import '../Styles/Documents.css';
import '../Styles/Tasks.css';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';
import { IoArrowForwardCircleSharp } from '@react-icons/all-files/io5/IoArrowForwardCircleSharp';
import NewEmployeeModal from './NewEmployeeModal';
import ApiService from '../Services/ApiService';
import { format } from 'date-fns';
import { useToast } from './ToastContext';
import { useData } from './DataContext';
import { FaTrash } from '@react-icons/all-files/fa/FaTrash';
import AccessListModal from './AccessListModal';

// Custom global filter function
function globalFilter(rows, ids, query) {
  const lowercasedQuery = query.toLowerCase();
  return rows.filter(row => {
    return ids.some(id => {
      const rowValue = row.values[id];
      if (id === 'creationDate' && rowValue) {
        const dateStr = new Date(rowValue).toISOString().substring(0, 10);
        return dateStr.toLowerCase().includes(lowercasedQuery);
      }
      return String(rowValue).toLowerCase().includes(lowercasedQuery);
    });
  });
}

const Admin = () => {
  const { advisors } = useData();
  const [employees, setEmployees] = useState(advisors);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAccessListModalOpen, setIsAccessListModalOpen] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [clientsAccess, setClientsAccess] = useState([]);
  const [documentsAccess, setDocumentsAccess] = useState([]);
  const { toast, showToast } = useToast();

  const fetchAllAccessForEmp = async (id) => {
    const payload = {
      accessTypes: [1, 2],
      employeeID: id,
    };
    const response = await ApiService.post('advisor/getAccessItemsForAdvisor', payload);
    const allAccess = await response.json();
    return allAccess.data;
  };

  useEffect(() => {
    const fetchAccess = async (id) => {
      setLoading(true);
      try {
        fetchAllAccessForEmp(id)
          .then(allAccess => {
            const cAccess = allAccess.filter(obj => obj.accessType === 1);
            const dAccess = allAccess.filter(obj => obj.accessType === 2);
            setClientsAccess(cAccess);
            setDocumentsAccess(dAccess);
            setLoading(false);
            setSelectedEmployeeId(null);
          })
          .catch(error => {
            console.error('Failed to load access!', error);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        showToast('Failed to load access!', 'failure');
      }
    };

    if (selectedEmployeeId) {
      fetchAccess(selectedEmployeeId);
    }
  }, [selectedEmployeeId]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenAccessListModal = (row) => {
    console.log(row.original.employeeId);
    setSelectedEmployeeId(row.original.employeeId);
    setIsAccessListModalOpen(true);
  };

  const handleCloseAccessListModal = () => {
    setIsAccessListModalOpen(false);
  };

  const columns = useMemo(
    () => [
      { Header: 'Employee Name', accessor: 'employeeName' },
      { Header: 'Employee Role', accessor: 'employeeType' },
      {
        Header: 'Employee Access',
        accessor: 'employeeId',
        Cell: ({ row }) => (
          <button className="access-list-btn" onClick={() => handleOpenAccessListModal(row)}>
            Access List <IoArrowForwardCircleSharp />
          </button>
        ),
      },
      {
        Header: '',
        accessor: 'disable',
        Cell: ({ value }) => (
          <span className="width-5%" onClick={() => handleClick(value)}>
            <FaTrash className="text-red-600 cursor-pointer" />
          </span>
        ),
      },
    ],
    []
  );

  const handleSaveEmployee = async (newEmployee) => {
    try {
      const response = await ApiService.post('advisor/addEmployee', newEmployee);
      if (response.ok) {
        const orderData = await response.json();
        setEmployees([...employees, orderData.data]);
        showToast('Employee created successfully!', 'success');
      } else {
        showToast('Failed to create Employee!', 'failure');
      }
    } catch (error) {
      showToast('Failed to create Employee', 'failure');
    }
  };

  const handleClick = (row) => {
    console.log(row);
  };

  const onRevoke = async (revokePayload) => {
    console.log('Revoked');
    try {
        const response = await ApiService.post('advisor/revokePermission', revokePayload);
        if (response.ok) {
          showToast('Employee access has been revoked successfully!', 'success');
          setSelectedEmployeeId(null);
        } else {
          showToast('Failed to revoke access of Employee!', 'failure');
        }
        handleCloseAccessListModal();
      } catch (error) {
        showToast('Failed to revoke access of Employee', 'failure');
        handleCloseAccessListModal();
      }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable({ columns, data: employees, globalFilter }, useGlobalFilter, useSortBy);

  return (
    <>
      <AccessListModal
        isOpen={isAccessListModalOpen}
        onClose={handleCloseAccessListModal}
        onRevoke={onRevoke}
        allClientsAccess={clientsAccess}
        allDocsAccess={documentsAccess}
        selectedEmployeeId={selectedEmployeeId}
      />
      <div className="add-doc-container">
        <button className="add-doc-btn" onClick={openModal}>
          <FaPlus /> Add Employee
        </button>
        {isModalOpen && (
          <NewEmployeeModal
            isOpen={isModalOpen}
            onClose={closeModal}
            onSave={handleSaveEmployee}
          />
        )}
      </div>
      <input
        type="text"
        onChange={(e) => setGlobalFilter(e.target.value)}
        placeholder="Search all columns"
        className="searchInput"
      />
      <Box position="relative">
        {loading && (
          <Flex
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            alignItems="center"
            justifyContent="center"
            backgroundColor="rgba(255, 255, 255, 0.7)"
            zIndex="10"
          >
            <Spinner size="xl" color="blue.500" />
          </Flex>
        )}
        <table {...getTableProps()} className="tasksContainer">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={
                      column.isSorted
                        ? column.isSortedDesc
                          ? 'sort-desc'
                          : 'sort-asc'
                        : ''
                    }
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Box>
    </>
  );
};

export default Admin;
