import React, { useMemo, useState, useEffect } from 'react';
import { useTable, useSortBy, useGlobalFilter } from 'react-table';
import { FiMoreVertical } from '@react-icons/all-files/fi/FiMoreVertical';
import '../Styles/Documents.css';
import '../Styles/Tasks.css';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';
import TaskModal from './TaskModal';
import { Link } from 'react-router-dom';
import ApiService from '../Services/ApiService';
import { format } from 'date-fns';
import Loader from './Loader';
import { useData } from './DataContext';
import { useToast } from './ToastContext';
import { BiTask } from "@react-icons/all-files/bi/BiTask";

// Custom global filter function
function globalFilter(rows, ids, query) {
    // Convert the search query to lower case
    const lowercasedQuery = query.toLowerCase();

    return rows.filter(row => {
        // Loop over each row and return true if any column matches the query
        return ids.some(id => {
            // Retrieve the value of the column in the current row
            const rowValue = row.values[id];

            // If it's a date, format it (or use whichever logic applies to your format)
            if (id === 'uploadDate' && rowValue) {
                // Format the date to a searchable string if necessary
                const dateStr = new Date(rowValue).toISOString().substring(0, 10);
                return dateStr.toLowerCase().includes(lowercasedQuery);
            }

            // For other types, ensure the value is a string and check if it includes the query
            return String(rowValue).toLowerCase().includes(lowercasedQuery);
        });
    });
}

const Tasks = () => {
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(false);
    const { toast, showToast } = useToast();
    const { clients, advisors, logedInAdvisorId } = useData();

    useEffect(() => {
        const fetchTasks = async () => {
            try {
                setLoading(true);
                const response = await ApiService.get("advisor/allTasks");
                if (response.ok) {
                    const result = await response.json();
                    setTasks(result.data);
                    setLoading(false);
                } else {
                    console.error("Failed to fetch tasks:", response.error);
                    setLoading(false);
                }
            } catch (error) {
                console.error("Failed to fetch tasks:", error);
                setLoading(false);
            }
        };

        fetchTasks();
    }, []);

    const handleSaveTask = async (taskDetails) => {
        try {
            taskDetails = {
                ...taskDetails,
                created_by: logedInAdvisorId
            }
            let { newDiscussion, ...taskDetailWithoutDiscussion } = taskDetails;
            let response = undefined;
            response = await ApiService.post('advisor/createTask', taskDetailWithoutDiscussion);

            if (!response.ok) {
                showToast('Failed to save task!', 'failure');
                throw new Error('Failed to save task');
            }
            const taskResponse = await response.json();
            setTasks([taskResponse.data, ...tasks]);

            if (taskDetails.newDiscussion) {
                const discussionPayload = {
                    "message": taskDetails.newDiscussion,
                    "taskId": taskResponse.data.id
                }
                try {
                    const discussionresp = await ApiService.post('advisor/addDiscussion', discussionPayload);
                    if (!discussionresp.ok) {
                        showToast('Failed to save discussion!', 'failure');
                        throw new Error('Failed to save task discussion');
                    }
                    showToast('Operation successful!', 'success');
                    console.log('Task discussion saved successfully');
                } catch (error) {
                    showToast('Operation failed!', 'failure');
                    console.log('Task discussion failed to save.');
                }

            }
            console.log('Task saved successfully');
        } catch (error) {
            console.error('Error saving task:', error);
        }
    };

    const columns = useMemo(() => [
        { Header: 'Task Name', accessor: 'name', Cell: ({ row }) => <a href={`/tasks/newTask?taskId=${row.original.id}`} className="hyperlink"><BiTask />{row.original.name}</a> },
        { Header: 'Description', accessor: 'description' },
        { Header: 'Created Date', accessor: 'created_date', Cell: ({ value }) => format(new Date(value), 'dd-MM-yyyy') },
        { Header: 'Deadline Date', accessor: 'deadline_date', Cell: ({ value }) => format(new Date(value), 'dd-MM-yyyy') },
        { Header: 'Due Date', accessor: 'due_date', Cell: ({ value }) => format(new Date(value), 'dd-MM-yyyy') },
        { Header: 'Status', accessor: 'status' },
        { Header: 'Client Name', accessor: 'client_name' },
        { Header: 'Assigned To', accessor: 'advisor_name' },
        { Header: 'Actions', accessor: 'actions', Cell: () => <button><FiMoreVertical /></button>, }
    ], []);

    const [isModalVisible, setModalVisible] = useState(false);

    const handleOpenModal = () => {
        console.log("opening model..");
        setModalVisible(true)
    };
    const handleCloseModal = () => setModalVisible(false);

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setGlobalFilter,
    } = useTable({ columns, data: tasks, globalFilter }, useGlobalFilter, useSortBy);

    // Render the UI for your table
    return (
        <>
            {loading ?
                <Loader></Loader>
                :
                (<>
                    <div className="add-doc-container">
                        <button className="add-doc-btn" onClick={handleOpenModal}>
                            <FaPlus /> Add Task
                        </button>
                        {/* <Link to="/newtask">
                            <button className="add-doc-btn">
                                <FaPlus /> Add Task
                            </button>
                        </Link> */}
                        <TaskModal
                            isVisible={isModalVisible}
                            advisors={advisors}
                            clients={clients}
                            onClose={handleCloseModal}
                            onSave={handleSaveTask}
                        />
                    </div>
                    <input
                        type="text"
                        onChange={e => setGlobalFilter(e.target.value)}
                        placeholder="Search all columns"
                        className="searchInput"
                    />
                    <table {...getTableProps()} className="tasksContainer">
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                            className={column.isSorted ? column.isSortedDesc ? 'sort-desc' : 'sort-asc' : ''}
                                        >
                                            {column.render('Header')}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map(row => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return (
                                                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </>)}
        </>
    );
};

export default Tasks;
