import React, { useEffect, useMemo, useState } from 'react';
import { FcFolder } from '@react-icons/all-files/fc/FcFolder';
import { FiFileText } from '@react-icons/all-files/fi/FiFileText';
import {MdShoppingCart } from '@react-icons/all-files/md/MdShoppingCart';
import { FaAngleDoubleRight } from "@react-icons/all-files/fa/FaAngleDoubleRight";
import { CgCornerLeftUp } from "@react-icons/all-files/cg/CgCornerLeftUp";
import * as Util from '../Utils/Util';
import { fileIcon } from '../Utils/FileUtil';
import { Progress, Box } from '@chakra-ui/react'

const DocumentMapperModal = ({onClose, onMoveItems, allDocuments, onFetchFolder, onFetchOrders, onFetchOrderFiles, allOrders, allOrderDocuments, topLevelFolderID, sourceFilesForMove }) => {
    const [selectedSourceItems, setSelectedSourceItems] = useState([]);
    const [destinationData, setDestinationData] = useState(allDocuments[topLevelFolderID]);
    const [destinationFolderID, setDestinationFolderID] = useState(topLevelFolderID);
    const [sourceData, setSourceData] = useState([]);
    const [sourceIsOrder, setSourceIsOrder] = useState(true);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const folderTrail = useMemo(() =>[],[]);
    const [currentOrderID, setCurrentOrderID] = useState(null);
    
    const handleSelectSourceItem = async(item) => {
        if (item.type === 'order'){
            if (showProgressBar) return;
            setShowProgressBar(true);
            setSelectedSourceItems([]);
            setSourceIsOrder(false);
            if (!allOrderDocuments.hasOwnProperty(item.id))
                await onFetchOrderFiles(item.id);
            const updatedOrderDocuments = allOrderDocuments[item.id].map(order => ({ ...order, type: "orderDocuments", isSelected: false }));
            setSourceData(updatedOrderDocuments);
            setCurrentOrderID(item.id);
            setShowProgressBar(false);
        }
        else if (item.type === 'orderDocuments'){
            if(selectedSourceItems.some(selectedItem => selectedItem.id === item.id)){
                console.log('unclicked' + item.id.toString());
                const filteredSelectedItems = selectedSourceItems.filter(selectedItem => selectedItem.id !== item.id);
                setSelectedSourceItems(filteredSelectedItems);
            }
            else{
                console.log('clicked' + item.id.toString());
                setSelectedSourceItems((prevSelected) => [...prevSelected, item]);
            }
        }
        else if(item.type === 'file'){
            //do nothing
        }
    };

    const handleBackOnSource = () =>{
        if (sourceFilesForMove === null && sourceIsOrder === false){
            setSourceIsOrder(true);
            const updatedOrders = allOrders.map(order => ({ ...order, type: "order", isSelected: false }));
            setSourceData(updatedOrders);
            setSelectedSourceItems([]);
        }
    }

    const handleMoveSelectedItems = async() => {
        if (showProgressBar) return;
        setShowProgressBar(true);
        if(sourceFilesForMove !== null){//for the directory file move
            await onMoveItems(sourceFilesForMove.map(item => item.billFolderMapID), destinationFolderID);
        }
        else{//for the order files move
            await onMoveItems(selectedSourceItems.map(item => item.id), destinationFolderID, currentOrderID);
            setSelectedSourceItems([]);
        }
        setShowProgressBar(false);
    };

    const handleClose = () =>{
        setSelectedSourceItems([]);
        onClose();
    }

    const onClickFolder = async(item) =>{
        if (showProgressBar) return;
        setShowProgressBar(true);
        if (!allDocuments.hasOwnProperty(item.id)){
            await onFetchFolder(item.id);
        }
        setDestinationData(allDocuments[item.id]);
        folderTrail.push(destinationFolderID);
        setDestinationFolderID(item.id);
        setShowProgressBar(false);
    }

    const handleBackOnDestination = () =>{
        if(folderTrail.length > 0){
            let _folderID = folderTrail.pop();
            setDestinationData(allDocuments[_folderID]);
            setDestinationFolderID(_folderID);
        }
    }

    const CustomDestinationRow = ({item}) =>{
        if(item.type === 'folder'){
            return  <span className="flex items-center space-x-2 select-none">
                        <FcFolder className="h-5 w-5"/>
                        <span className="hover:underline cursor-pointer" onClick={() => onClickFolder(item)}>{item.name}</span>
                    </span>
        }
        else if(item.type === 'file'){
            return  <span className="flex items-center space-x-2">
                        {fileIcon(item.fileType)}
                        <span>{item.name}</span>
                    </span>
        }

    }

    const CustomSourceRow = ({item}) => {
        if (item.type === 'order'){
            let orderStr = 'Order No. ' + item.id.toString() + ' (' + Util.formatDate(item.creationDate) + ')';
            return  <span className="flex items-center space-x-2 select-none cursor-pointer">
                        {<MdShoppingCart /> }
                        <span className="hover:underline cursor-pointer" onClick={() => handleSelectSourceItem(item)}>{orderStr}</span>
                    </span>
        }
        else if (item.type === 'orderDocuments'){
            let _itemSelected = selectedSourceItems.some(selectedItem => selectedItem.id === item.id);
            return  <span className={`flex items-center space-x-2 select-none cursor-pointer ${_itemSelected ? "bg-orange-300 rounded-sm" : ''}`}
                        onClick={() => handleSelectSourceItem(item)}>
                        {fileIcon(item.fileType)}
                        <span className="hover:underline cursor-pointer" >{item.fileName}</span>
                    </span>
        }
        else if(item.type === 'file'){
            let _itemSelected = true;
            return  <span className={`flex items-center space-x-2 select-none cursor-pointer ${_itemSelected ? "bg-orange-300 rounded-sm" : ''}`}
                        onClick={() => handleSelectSourceItem(item)}>
                        {fileIcon(item.fileType)}
                        <span className="" >{item.name}</span>
                    </span>
        }
    }

    useEffect(() =>{
        const fetchOrderData = async() => {
            await onFetchOrders();
        }

        if(sourceFilesForMove === null)
            fetchOrderData();
    },[]);

    useEffect(() =>{
        if(sourceFilesForMove !== null){//for the directory file move
            const updatedFiles = sourceFilesForMove.map(order => ({ ...order, type: "file", isSelected: true }));
            setSourceData(updatedFiles);
        }
        else{//for the order files move
            const updatedOrders = allOrders.map(order => ({ ...order, type: "order", isSelected: false }));
            setSourceData(updatedOrders);
        }
    },[allOrders, sourceFilesForMove]);

    return (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
            <div className="flex flex-col w-full max-w-4xl bg-white shadow-md space-x-4 h-5/6 rounded-lg p-6">
                <div className="h-2">
                    {showProgressBar && <Progress size='xs' isIndeterminate colorScheme='orange' backgroundColor="#ffffff"/>}
                </div>
                <div className="bg-white flex h-5/6">
                    <button onClick={handleBackOnSource} className="mx-auto p-2 h-10 w-10 bg-white text-white rounded-md hover:bg-gray-200 transition-colors">
                        <CgCornerLeftUp className="h-6 w-6" color='#fe5f00'/>
                    </button>
                    <div className="flex-1 overflow-auto bg-orange-50 border-2 border-black rounded-sm">
                        <ul>
                            {sourceData.map((item) => (
                                <li key={item.id} className="cursor-pointer hover:bg-orange-100 p-2">
                                    <CustomSourceRow item={item}/>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="flex flex-col justify-center m-2">
                        <button onClick={handleMoveSelectedItems} className="mx-auto p-2 bg-orange-500 text-white rounded-md hover:bg-blue-700 transition-colors">
                            {/* Icon or text for moving items */}
                            <FaAngleDoubleRight className="h-5 w-5" color="#ffffff"/>
                        </button>
                    </div>
                    
                    <button onClick={handleBackOnDestination} className="mx-auto p-2 h-10 w-10 bg-white text-white rounded-md hover:bg-gray-200 transition-colors">
                        <CgCornerLeftUp className="h-6 w-6" color='#fe5f00'/>
                    </button>
                    <div className="flex-1 overflow-auto bg-green-100 border-2 border-black rounded-sm">
                        <ul>
                            {destinationData.map((item) => (
                                <li key={item.key} className="p-2 hover:bg-green-200">
                                    <CustomDestinationRow item={item}/>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="flex justify-end space-x-2 pt-10">
                    <button className="px-4 py-2 bg-orange-300 text-white rounded hover:bg-orange-400" onClick={handleClose}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default DocumentMapperModal;
