import React, { useEffect, useState } from 'react';
import '../Styles/OrderModal.css';

// Assuming orders is an array of order objects and skills is an array for dropdown options
const OrderModal = ({ mode, isMapping, orders, skills, clients, allMappedOrders, onClose, onSaveMapping, onOrderSave, onFilesOrderMap }) => {
    const [selectedOrders, setSelectedOrders] = React.useState(mode=='taskMap' ? [...allMappedOrders] : []);
    const [selectedOrderToMapFiles, setSelectedOrderToMapFiles] = useState(null);
    const [selectedForMapping, setSelectedForMapping] = useState([]);
    const [selectedForUnmapping, setSelectedForUnmapping] = useState([]);
    const [orderDetails, setOrderDetails] = React.useState({
        userId: '',
        skillType: '',
        skillId: '',
        price: ''
    });
    const skillTypes = [
        { id: 0, name: "One Time" },
        { id: 0, name: "Monthly" },
        { id: 0, name: "Yearly" }
    ]
    const [isPaymentOnline, setIsPaymentOnline] = useState(false); // Default to online payment

    const handlePaymentChange = (event) => {
        setIsPaymentOnline(event.target.value === 'online');
    };

    const toggleOrderSelection = (order) => {
        if(mode=='taskMap'){
            if (allMappedOrders.some(mappedOrder => mappedOrder.id === order.id)) {
                allMappedOrders.splice(allMappedOrders.findIndex(item => item.id === order.id), 1);
                setSelectedOrders([...allMappedOrders])
                if (selectedForUnmapping.some(selectedOrder => selectedOrder.id === order.id)) {
                    setSelectedForUnmapping(current =>
                        current.filter(selectedOrder => selectedOrder.id !== order.id));
                } else {
                    setSelectedForUnmapping(current => [...current, order]);
                }
            } else {
                if (selectedForMapping.some(selectedOrder => selectedOrder.id === order.id)) {
                    setSelectedForMapping(current =>
                        current.filter(selectedOrder => selectedOrder.id !== order.id));
                } else {
                    setSelectedForMapping(current => [...current, order]);
                }
            }
        }
        
    };

    const handleChange = (e) => {
        let { name, value } = e.target;

        if (name === 'skillId') {
            const selectedSkill = skills.find(skill => skill.id.toString() === value);
            if (selectedSkill) {
                setOrderDetails(prevDetails => ({
                    ...prevDetails,
                    price: selectedSkill.skillPrice,
                    skillId: value
                }));
            }
        }

        if (name !== 'price') {
            value = parseInt(value)
        }
        setOrderDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value
        }));
    };

    const handleSave = () => {
        if (mode == 'taskMap') {
            onSaveMapping(selectedForMapping, selectedForUnmapping,
            );
        } else if(mode == 'fileMap') {
            onFilesOrderMap(selectedOrderToMapFiles)
        } else {
            onOrderSave({...orderDetails, isPaymentOnline: isPaymentOnline});
        }
    };

    return (
        <div className="modal">
            <div className="modal-content">
                <span className="close-button" onClick={onClose}>&times;</span>
                {mode == 'taskMap' || mode == 'fileMap' ? (
                    <div className="mapping-section">
                        <table className="orders-table">
                            <thead>
                                <tr>
                                    <th className="checkbox-column"></th> {/* For checkbox */}
                                    <th>Order ID</th>
                                    <th>Client Name</th>
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orders.map(order => (
                                    <tr key={order.id}>
                                        <td className="checkbox-column">
                                            {mode == 'taskMap' ? <input
                                                type="checkbox"
                                                id={`order-${order.id}`}
                                                name="selectedOrder"
                                                value={order.id}
                                                checked={selectedOrders.some(selectedOrder => selectedOrder.id === order.id) || selectedForMapping.some(selectedOrder => selectedOrder.id === order.id)}
                                                onChange={() => toggleOrderSelection(order)}
                                            /> : 
                                            <input
                                                type="radio"
                                                id={`order-${order.id}`}
                                                name="selectedOrder"
                                                value={order.id}
                                                onChange={() => setSelectedOrderToMapFiles(order.id)}
                                            />
                                            }
                                        </td>
                                        <td>{order.id}</td>
                                        <td>{order.clientName}</td>
                                        <td>{order.price}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div className="form-section">
                        {/* Client Dropdown */}
                        <div className="form-group">
                            <label htmlFor="userId">Client</label>
                            <select name="userId" id="userId" value={orderDetails.userId} onChange={handleChange}>
                                <option value="" disabled>Select Client</option>
                                {clients && clients.map((client) => (
                                    <option key={client.userId} value={client.userId}>{`${client.userFirstName} ${client.userLastName}`}</option>
                                ))}
                            </select>
                        </div>
                        {/* Skill Type Dropdown */}
                        <div className="form-group">
                            <label htmlFor="skillType">Skill Type</label>
                            <select name="skillType" id="skillType" value={orderDetails.skillType} onChange={handleChange}>
                                <option value="" disabled>Select Skill Type</option>
                                {skillTypes && skillTypes.map((skillType) => (
                                    <option key={skillType.id} value={skillType.id}>{skillType.name}</option>
                                ))}
                                {/* Skills dropdown options */}
                            </select>
                        </div>
                        {/* Skill ID Dropdown */}
                        <div className="form-group">
                            <label htmlFor="skillId">Skill ID</label>
                            <select name="skillId" id="skillId" value={orderDetails.skillId} onChange={handleChange}>
                                <option value="" disabled>Select Skill Id</option>
                                {skills && skills.map((skill) => (
                                    <option key={skill.id} value={skill.id}>{skill.skillName}</option>
                                ))}
                                {/* Skill IDs dropdown options */}
                            </select>
                        </div>
                        {/* Price Input */}
                        <div className="form-group">
                            <label htmlFor="price">Price</label>
                            <input type="number" id="price" name="price" value={orderDetails.price} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <div className="payment-options">
                                <div className="radio-option">
                                    <input
                                        type="radio"
                                        id="paymentOnline"
                                        name="paymentType"
                                        value="online"
                                        checked={isPaymentOnline}
                                        onChange={handlePaymentChange}
                                    />
                                    <label htmlFor="paymentOnline">Pay through App</label>
                                </div>

                                <div className="radio-option">
                                    <input
                                        type="radio"
                                        id="paymentOffline"
                                        name="paymentType"
                                        value="offline"
                                        checked={!isPaymentOnline}
                                        onChange={handlePaymentChange}
                                    />
                                    <label htmlFor="paymentOffline">Pay Offline</label>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="modal-footer">
                    <button className="btn-save" onClick={handleSave}>{(mode == 'taskMap' || mode == 'fileMap') ? 'Add' : 'Save'}</button>
                    <button className="btn-close" onClick={onClose}>Close</button>
                </div>
            </div>
        </div>
    );
};

export default OrderModal;
