import React, { useState } from 'react';
import Orders from './Orders';
import { useData } from './DataContext';

const OrdersContainer = () => {
    const { clients, skillList, orderList } = useData();
    return (
        <Orders
            ordersList={orderList}
            clients={clients}
            skillList={skillList}
        />
    );
};

export default OrdersContainer;
