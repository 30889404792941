import React, { useEffect, useState } from 'react';
import '../Styles/TaskDetailComponent.css';
import '../Styles/TaskModal.css'; // Make sure the CSS file path is correct
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Default styling
import { FaRegCalendarAlt } from '@react-icons/all-files/fa/FaRegCalendarAlt';
import { FaSitemap } from '@react-icons/all-files/fa/FaSitemap';
import { useData } from './DataContext';
import { useToast } from './ToastContext';
import Toast from './ToastComponent';
import ApiService from '../Services/ApiService';
import Loader from './Loader';
import { format } from 'date-fns';
import OrderModal from './OrderModal';
import { Switch } from '@chakra-ui/react'

const DetailsView = ({ onSave, saveRequested, setSaveRequested }) => {
  const [discussions, setDiscussions] = useState([]);
  const [showNewDiscussionInput, setShowNewDiscussionInput] = useState(false);
  const { clients, advisors, logedInAdvisorId, skillList, orderList } = useData();
  const [showPayments, setShowPayments] = useState(false);
  const [userHasAccess, setUserHasAccess] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState(null);
  const [orders, setOrders] = useState(orderList);
  const [mappedOrders, setmMppedOrders] = useState([]);

  const searchParams = new URLSearchParams(window.location.search);
  const taskId = searchParams.get('taskId');

  const payments = [
    { id: 1, amount: "$100", date: "2023-01-01" },
    { id: 2, amount: "$200", date: "2023-01-02" },
    // Add more payments as needed
  ];

  const initialFormState = {
    name: '',
    description: '',
    selectedClient: '',
    selectedAdvisor: '',
    due_date: new Date(),
    deadline_date: new Date(),
    newDiscussion: ''
  };
  const [formData, setFormData] = useState(initialFormState);
  const [initialFormData, setInitialFormData] = useState({});
  const { toast, showToast } = useToast();

  const openModal = (mode) => {
    setModalMode(mode);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalMode(null);
  };

  // Handle change for form inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // Dedicated handler for the due_date ReactDatePicker
  const handleDueDateChange = (date) => {
    setFormData((prevState) => ({
      ...prevState,
      due_date: date,
    }));
  };

  // Dedicated handler for the deadline_date ReactDatePicker
  const handleDeadlineChange = (date) => {
    setFormData((prevState) => ({
      ...prevState,
      deadline_date: date,
    }));
  };

  const togglePaymentHistory = () => {
    // If the user has access, allow them to toggle the payment history visibility
    if (userHasAccess) {
      setShowPayments(!showPayments);
    }
  };

  const onSaveMapping = async (forMapping, forUnMapping) => {
    if(forUnMapping.length > 0){
      const orderIds = forUnMapping.map(obj => obj.id);
      try {
        const unmapData = {
          orderIDs: orderIds
        }
        const response = await ApiService.post('order/unMapOrderFromTask?taskId=' + taskId, unmapData);
        if (response.ok) {
          let updatedMappedOrder = mappedOrders.filter(obj => !forUnMapping.some(subObj =>
            obj.id === subObj.id
          ));
          setmMppedOrders([ ...updatedMappedOrder]);
          showToast('Order un-mapped successful!', 'success');
        } else {
          showToast('Failed to un-mapped Order!', 'failure');
        }
      } catch (error) {
        showToast('Failed to un-mapped Order', 'failure');
      }
    }
    
    if(forUnMapping.length > 0){
      for(const order of forMapping) {
        try {
          const mapData = {
            taskId: parseInt(taskId),
            orderId: order.id
          }
          const response = await ApiService.post('order/mapOrderToTask', mapData);
          if (response.ok) {
            const orderData = await response.json();
            setmMppedOrders([ orderData.data, ...mappedOrders]);
            showToast('Order saved successful!', 'success');
          } else {
            showToast('Failed to save Order!', 'failure');
          }
        } catch (error) {
          showToast('Failed to save Order', 'failure');
        }
      }
    }
  };

  const onOrderSave = async (orederDetail) => {
    console.log("order save")
    try {
      const response = await ApiService.post('order/createOrder', orederDetail);
      if (response.ok) {
        const orderData = await response.json();
        setOrders([...mappedOrders, orderData.data]);
        showToast('Order saved successful!', 'success');
      } else {
        showToast('Failed to save Order!', 'failure');
      }
    } catch (error) {
      showToast('Failed to save Order', 'failure');
    }

  };

  const prepareUpdateData = (taskId) => {
    const updatedFields = {
      taskId: taskId
    };
    Object.keys(formData).forEach(key => {
      if (formData[key] !== initialFormData[key]) {
        if(key == 'due_date' || key == 'deadline_date'){
          updatedFields[key] = formData[key].toISOString();
        } else{
          updatedFields[key] = formData[key];
        }
      }
    });
    return updatedFields;
  };


  const fetchOrders = async (id) => {
    const response = await ApiService.get("order/allOrdersForTask?taskId=" + id);
    const mappedOrders = await response.json();
    return mappedOrders.data;
  };

  const fetchTask = async (id) => {
    try {
      const response = await ApiService.get("advisor/taskDetail?taskId=" + id);
      const taskDetail = await response.json();
      return taskDetail.data;
    } catch (error) {
      return null
    }

  };

  useEffect(() => {
    const fetchTaskDetails = async (id) => {
      setLoading(true);
      try {
        Promise.all([fetchOrders(id), fetchTask(id)])
          .then(([orders, task]) => {
            setmMppedOrders(orders);
            const taskDataForForm = {
              name: task.name,
              description: task.description,
              selectedClient: task.clientId.toString(),
              selectedAdvisor: task.assigned_to.toString(),
              due_date: new Date(task.due_date),
              deadline_date: new Date(task.deadline_date)
            };
            setFormData(taskDataForForm);
            setInitialFormData(taskDataForForm);
            setDiscussions(task.discussions)
            setLoading(false);
          })
          .catch((error) => {
            console.error("Failed to load task detail!", error);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        showToast('Failed to load task detail!', 'failure');
      }


      // Set other state as necessary based on the fetched data
    };

    if (taskId) {
      fetchTaskDetails(taskId);
    }
  }, [taskId]);

  useEffect(() => {
    const saveData = async () => {
      if (saveRequested) {
        try {
          if(formData === initialFormData){
            showToast('Please fill task details!', 'failure');
            return;
          }

          if (taskId) {
            const taskDetailsToUpdate = prepareUpdateData(parseInt(taskId));
            await onSave(taskDetailsToUpdate, true);
          }
        } catch (error) {
          showToast('Operation failed!', 'failure');
          console.error("Error saving data:", error);
        } finally {
          setSaveRequested(false);
        }
      }
    };

    saveData();
  }, [saveRequested])

  // Placeholder for Details view component content
  return (
    <>
      {loading ?
        <Loader></Loader>
        : (<>
          <div className="details-view">
            <div className="left-section">
              {/* Name, Description, Discussions, Add New Discussion */}
              <div className="assigned-container">
                <div className="client-input">
                  <label htmlFor="orderAmount">Client</label>
                  <select
                    id="client-select"
                    name="selectedClient"
                    value={formData.selectedClient}
                    onChange={handleChange}
                  >
                    <option value="">Select a client</option>
                    {clients && clients.map((client) => (
                      <option key={client.userId} value={client.userId}>{`${client.userFirstName} ${client.userLastName}`}</option>
                    ))}
                  </select>
                </div>
                <div className="client-input">
                  <label htmlFor="receivedAmount">Assigned To</label>
                  <select
                    id="advisor-select"
                    name="selectedAdvisor"
                    value={formData.selectedAdvisor}
                    onChange={handleChange}
                  >
                    <option value="">Select an advisor</option>
                    {advisors ? advisors.map((advisor) => (
                      <option key={advisor.employeeId} value={advisor.employeeId}>{advisor.employeeName}</option>
                    )) : []}
                  </select>
                </div>
              </div>
              <input
                className="task-input"
                type="text"
                placeholder="Task Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
              <textarea
                className="task-textarea"
                placeholder="Description"
                name="description"
                value={formData.description}
                onChange={handleChange}
              />
              <div className="date-fields-container">
                <div className="date-field">
                  <label htmlFor="due_date">Due Date</label>
                  <div className="date-input-container">
                    <FaRegCalendarAlt className="calendar-icon" />
                    <ReactDatePicker
                      selected={formData.due_date}
                      onChange={handleDueDateChange}
                      className="date-picker-input"
                    />
                  </div>
                </div>
                <div className="date-field">
                  <label htmlFor="deadline_date">deadline_date</label>
                  <div className="date-input-container">
                    <FaRegCalendarAlt className="calendar-icon" />
                    <ReactDatePicker
                      selected={formData.deadline_date}
                      onChange={handleDeadlineChange}
                      className="date-picker-input"
                    />
                  </div>
                </div>
              </div>
              {discussions.length > 0 && (
                <div className="discussion-section">
                  {discussions.map((discussion, index) => (
                    <div key={index} className="discussion-card">
                      <div className="discussion-text">{discussion.message}</div>
                      <div className="discussion-meta">
                        <span className="discussion-author">{discussion.writerName}</span>
                        <span className="discussion-time">{format(new Date(discussion.timestamp), 'dd-MM-yyyy hh:mm a')}</span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {showNewDiscussionInput && (
                <textarea
                  className="task-textarea"
                  placeholder="Add new discussion"
                  name="newDiscussion"
                  value={formData.newDiscussion}
                  onChange={handleChange}
                  autoFocus
                />
              )}
              {!showNewDiscussionInput && (
                <div className="add-discussion-link" onClick={() => setShowNewDiscussionInput(true)}>
                  <FaPlus /> Add Discussion
                </div>
              )}

            </div>
            <div className="right-section">
              {/* Linked Orders and Payment History */}
              <div className="linked-orders">
                <h3 className="linked-orders-headers">Linked Orders
                  <span onClick={() => openModal('map')} className="orderIcon">
                    {<FaSitemap />}
                  </span>
                  <span onClick={() => openModal('create')} className="orderIcon">
                    {<FaPlus />}
                  </span>
                </h3>
                {mappedOrders.map((order) => (
                  <a key={order.id} href={order.skillName} className="order-link">{`Order No${order.id}. ${format(new Date(order.creationDate), 'dd-MM-yyyy')}`}</a>
                ))}
                {isModalOpen && (
                  <OrderModal
                    mode='map'
                    isMapping={modalMode === 'map'}
                    skills={skillList}
                    orders={orders}
                    clients={clients}
                    allMappedOrders={mappedOrders}
                    onClose={closeModal}
                    onSaveMapping={onSaveMapping}
                    onOrderSave={onOrderSave} // Implement or replace with your actual save function
                  />
                )}
              </div>
              <div className="payment-history">
                <h3>Payment History</h3>
                <button
                  className={`view-link ${!userHasAccess ? 'disabled' : ''}`}
                  onClick={togglePaymentHistory}
                  disabled={!userHasAccess}
                >
                  View
                </button>
                {showPayments && payments.map((payment) => (
                  <div key={payment.id} className="payment-detail">
                    <span>{payment.amount}</span>
                    <span>{payment.date}</span>
                  </div>
                ))}
              </div>
            </div>
            <Toast message={toast.message} type={toast.type} />
          </div>
        </>)
      }
    </>


  );
};

export default DetailsView