import React from 'react';

const FolderTrailComponent = ({ items, onItemClick }) => {
  return (
    <div>
      <ol className="flex space-x-2 pl-0">
        {items.map((item, index) => (
          <li key={index} className={`${index < items.length - 1 ? 'text-black' : 'text-gray-500'} font-medium`}>
            {index < items.length - 1 ? (
			<div>
			<span className='underline select-none cursor-pointer' onClick={()=>onItemClick(item.folderID, item.name, true)}>{item.name}</span>
			<span>{" > "}</span>
			</div>
			  
            ) : (
              <span>{item.name}</span>
            )}
          </li>
        ))}
      </ol>
    </div>
  );
};


export default FolderTrailComponent;