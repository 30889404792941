import React, { useState, useEffect } from 'react';
import '../Styles/Login.css'
import ApiService from '../Services/ApiService';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [btnclicked, setBtnclicked] = useState(false);
    const { login, isLoggedIn } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn) {
            navigate('/clients'); // Redirect to the dashboard or another appropriate page
        }
    }, [isLoggedIn, navigate]);

    const handlePhoneNumberChange = (e) => {
        setPhoneNumber(e.target.value);
    };

    const handleOtpChange = (e) => {
        setOtp(e.target.value);
    };

    const handleSendOtp = async () => {
        // Code to send OTP to the phone number
        // For demo, just logging OTP to console
        setBtnclicked(true);
        console.log("OTP sent to", phoneNumber);
        try {
            const endpoint = "advisor/signin/sendOTP?phoneNumber=" + phoneNumber + "&isVoiceOTP=False"
            const response = await ApiService.get(endpoint);
            if (response.status == 200) {
                setBtnclicked(false);
                setOtpSent(true);
            }

        } catch (error) {
            console.log(error);
        }

    };

    const handleLogin = async () => {
        // Code to verify OTP and perform login
        setBtnclicked(true);
        console.log("Logging in with OTP:", otp);
        try {
            const endpoint = "advisor/signin/verifyOTP?phoneNumber=" + phoneNumber + "&OTP=" + otp
            const response = await ApiService.get(endpoint);
            if (response.status == 200) {
                setBtnclicked(false);
                const responseBody = await response.json();
                document.cookie = `accessToken=${responseBody.accesstoken}; Path=/; Secure; SameSite=None`;
                document.cookie = `refreshToken=${responseBody.refreshtoken}; Path=/; Secure; SameSite=None`;
                login();
                navigate('/clients');
            }

        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="login-background">
            <div className="login-container">
                <h2>Login</h2>
                <input
                    type="tel"
                    placeholder="Phone Number"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                />
                {otpSent ? (
                    <>
                        <input
                            type="number"
                            placeholder="OTP"
                            value={otp}
                            onChange={handleOtpChange}
                        />
                        <button onClick={handleLogin} disabled={btnclicked}>Login</button>
                    </>
                ) : (
                    <button onClick={handleSendOtp} disabled={btnclicked}>Send OTP</button>
                )}
            </div>
        </div>
    );
}

export default Login;