import React, { useState } from 'react';

const DocumentOperationModal = ({ isVisible, onClose, onSave, header, selectedRow=null }) => {


    const [newFolderName, setNewFolderName] = useState('');

    const handleNameChange = (e) => {
        setNewFolderName(e.target.value);
    };

    const handleClose = () =>{
        setNewFolderName("");
        onClose();
    }

    const handleSave = (taskData) =>{
        if(header === 'New Folder'){
            onSave(taskData);
        }
        else if(header === 'Rename Folder' && selectedRow !== null){
            onSave(taskData, selectedRow.id);
        }
        handleClose();
    }

    if (!isVisible) return null;

    let placeHolderText = '';
    if(header === 'New Folder') placeHolderText = 'New folder name';
    if(header === 'Rename Folder' && selectedRow !== null) {
        placeHolderText = selectedRow.name;
    }

    return (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-md w-11/12 max-w-lg flex flex-col space-y-4">
            <div className="flex flex-col items-center space-y-2">
                <h2 className="text-lg font-semibold">{header}</h2>
                <div className="flex items-center mt-2 mb-2 w-full border border-gray-300 rounded-sm focus-within:border-orange-500 focus-within:ring focus-within:ring-orange-200 focus-within:ring-opacity-50">
                    <input
                        type='text'
                        placeholder={placeHolderText}
                        value={newFolderName}
                        onChange={handleNameChange}
                        autoFocus
                        className="p-2 w-full focus:outline-none"
                    />
                </div>
            </div>
            <div className="flex justify-end space-x-2">
                <button className="px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-700" onClick={() => handleSave(newFolderName)}>Save</button>
                <button className="px-4 py-2 bg-orange-300 text-white rounded hover:bg-orange-400" onClick={handleClose}>Cancel</button>
            </div>
            </div>
        </div>
    );
};

export default DocumentOperationModal;
