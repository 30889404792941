import AuthUtils from "../Utils/AuthUtils";

class ApiService {
    static baseUrl = 'https://api.iova.app/api/';
  
    static async get(endpoint, params=null) {
      try {
        let headers = {};
        if(!endpoint.includes("signin")){
          headers = await AuthUtils.getHeadersForAuthRequest();
        }
        let finalBaseURL = `${this.baseUrl}${endpoint}`
        const url = new URL(finalBaseURL);
        if(params !== null)
          Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
        const response = await fetch(url, {
          method: 'GET',
          headers: headers
        });
        return response;
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
      }
    }
  
    static async post(endpoint, data) {
      try {
        const headers = await AuthUtils.getHeadersForAuthRequest();
        const response = await fetch(`${this.baseUrl}${endpoint}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            ...headers,
          },
          body: JSON.stringify(data),
        });
        return response;
      } catch (error) {
        console.error('Error posting data:', error);
        throw error;
      }
    }

    static async postform(endpoint, formData) {
      try {
        const headers = await AuthUtils.getHeadersForAuthRequest();
        const response = await fetch(`${this.baseUrl}${endpoint}`, {
          method: 'POST',
          headers: {
            ...headers,
          },
          body: formData,
        });
        return response;
      } catch (error) {
        console.error('Error posting data:', error);
        throw error;
      }
    }
  
    static async put(endpoint, data) {
        try {
          const headers = await AuthUtils.getHeadersForAuthRequest();
          const response = await fetch(`${this.baseUrl}${endpoint}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              ...headers,
            },
            body: JSON.stringify(data),
          });
          return response;
        } catch (error) {
          console.error('Error posting data:', error);
          throw error;
        }
      }
  }
  
  export default ApiService;
  