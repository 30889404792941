import React from 'react';
import { NavLink, useLocation  } from 'react-router-dom';
import '../Styles/SideMenu.css';
// If you use an icon library, import your icons here
// e.g., import { FiUsers, FiList } from 'react-icons/fi';
import { RiFolderUserFill } from "@react-icons/all-files/ri/RiFolderUserFill";
import { FaTasks } from "@react-icons/all-files/fa/FaTasks";
import { FaFileInvoiceDollar } from "@react-icons/all-files/fa/FaFileInvoiceDollar";
import { RiAdminFill } from "@react-icons/all-files/ri/RiAdminFill";
import { useAuth } from './AuthContext';
import logo from '../images/iova-logo-2.png'

const SideMenu = () => {
  const { isAdmin } = useAuth();

  const location = useLocation();

  const isActive = (path) => {
    return location.pathname.startsWith(path);
  };

  return (
    <div className="sideMenu">
      <div className="logo-container">
        <img src={logo} alt="IOVA Logo" className="logo-img" />
        <span className="label">IOVA</span>
      </div>
      <ul>
        <li>
          <NavLink to="/clients" activeClassName="active">
            {/* Replace 'C' with <FiUsers /> or your chosen client icon */}
            <span className="icon"><RiFolderUserFill className="icon" size={24} /></span>
            <span className="label">Client</span>
          </NavLink >
        </li>
        <li>
          <NavLink to="/tasks" isActive={() => isActive('/tasks')} activeClassName="active">
            {/* Replace 'T' with <FiList /> or your chosen tasks icon */}
            <span className="icon"><FaTasks className="icon" size={24} /></span>
            <span className="label">Tasks</span>
          </NavLink >
        </li>
        <li>
          <NavLink to="/orders" activeClassName="active">
            {/* Replace 'T' with <FiList /> or your chosen tasks icon */}
            <span className="icon"><FaFileInvoiceDollar className="icon" size={24} /></span>
            <span className="label">Orders</span>
          </NavLink >
        </li>
        {isAdmin ? <li>
          <NavLink to="/admin" activeClassName="active">
            {/* Replace 'T' with <FiList /> or your chosen tasks icon */}
            <span className="icon"><RiAdminFill className="icon" size={24} /></span>
            <span className="label">Admin</span>
          </NavLink >
        </li>
          : null}
      </ul>
    </div>
  );
};

export default SideMenu;
