import React, { useState, useCallback, useEffect } from 'react';
import { FaCloudUploadAlt } from '@react-icons/all-files/fa/FaCloudUploadAlt';
import { useDropzone } from 'react-dropzone';

const DocumentUploadModal = ({ isVisible, onClose, onSave, droppableFiles=null, dropParentFolderID=null }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  /*const handleFileChange = (event) => {
    const files = Array.from(event.target.files).map(file => ({
      fileObj: file,
      fileNote: ''
    }));
    console.log(files);
    setSelectedFiles(files);
  };*/

  const handleNoteChange = (index, note) => {
    const newFiles = [...selectedFiles];
    newFiles[index].fileNote = note;
    setSelectedFiles(newFiles);
  };

  const handleSave = () => {
		onSave(selectedFiles, dropParentFolderID);
		handleClose();
  };

  const handleClose = () =>{
		setSelectedFiles([]);
		onClose();
  }

  const onDrop = useCallback(acceptedFiles => {
	const files = Array.from(acceptedFiles).map(file => ({
		fileObj: file,
		fileNote: ''
	}));
	console.log(files);
	setSelectedFiles(files);
  }, []);

  useEffect(() =>{
	if(droppableFiles !== null){
		const files = Array.from(droppableFiles).map(file => ({
			fileObj: file,
			fileNote: ''
		}));
		console.log(files);
		setSelectedFiles(files);
	}
	},[droppableFiles]);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({ onDrop });

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
		<div className="bg-white p-6 rounded-lg shadow-md w-11/12 max-w-lg flex flex-col space-y-4">
			<div className="flex flex-col items-center space-y-2">
				{/*<label className="inline-flex items-center space-x-2 px-4 py-2 bg-orange-500 text-white rounded cursor-pointer hover:bg-orange-700">
					<FaCloudUploadAlt />
					<span>Select Files</span>
					<input type="file" className="hidden" onChange={handleFileChange} multiple />
  				</label>*/}
				{/* Scrollable list container */}
				<div className="w-full max-h-80 overflow-auto">
					{selectedFiles.map((file, index) => (
					<div key={index} className="flex flex-col space-y-1 p-2 border-2 border-orange-500 rounded-md my-2">
						<span className="text-md text-gray-800">{file.fileObj.name}</span>
						<textarea
						className="border-2 p-2 border-gray-200 rounded resize-y h-12 shadow-inner"
						placeholder="Add notes for this file..."
						value={file.fileNote}
						maxLength={250}
						onChange={(e) => handleNoteChange(index, e.target.value)}
						/>
					</div>
					))}
				</div>
			</div>
			
			{selectedFiles.length === 0 && 
			<div {...getRootProps()} className="inline-flex h-20 items-center space-x-2 px-4 py-2 border-2 border-orange-500 text-white rounded cursor-pointer hover:bg-gray-100">
				<input {...getInputProps()} />
				{
					isDragActive ?
					<p className="text-black font-bold">Drop the files here ...</p> :
					<p className="text-black font-bold">
						Drag 'n' drop some files here, or <span className="text-blue-500 underline">click here</span> to select files
					</p>
				}
			</div>}
			<div className="flex justify-end space-x-2">
				<button className="px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-700" onClick={handleSave}>Save</button>
				<button className="px-4 py-2 bg-orange-300 text-white rounded hover:bg-orange-400" onClick={handleClose}>Cancel</button>
			</div>
		</div>
    </div>
  );
  
};

export default DocumentUploadModal;
