import React, { useState } from 'react';
import '../Styles/TaskModal.css';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaRegCalendarAlt } from '@react-icons/all-files/fa/FaRegCalendarAlt';

const TaskModal = ({ isVisible, onClose, onSave, clients, advisors }) => {
    const [newDiscussion, setNewDiscussion] = useState('');
    const [showNewDiscussionInput, setShowNewDiscussionInput] = useState(false);

    const initialFormState = {
        name: '',
        description: '',
        selectedClient: '',
        selectedAdvisor: '',
        dueDate: new Date(),
        deadline: new Date(),
        newDiscussion: ''
    };
    const [formData, setFormData] = useState(initialFormState);

    // Handle change for form inputs
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // Dedicated handler for the dueDate ReactDatePicker
    const handleDueDateChange = (date) => {
        setFormData((prevState) => ({
            ...prevState,
            dueDate: date,
        }));
    };

    // Dedicated handler for the deadline ReactDatePicker
    const handleDeadlineChange = (date) => {
        setFormData((prevState) => ({
            ...prevState,
            deadline: date,
        }));
    };

    const handleSave = async () => {
        const taskDetails = {
            name: formData.name,
            description: formData.description, 
            clientId: parseInt(formData.selectedClient),
            assigned_to: parseInt(formData.selectedAdvisor),
            due_date: formData.dueDate.toISOString(),
            deadline_date: formData.deadline.toISOString(),
            newDiscussion: formData.newDiscussion
          };
          await onSave(taskDetails);
          setFormData(initialFormState);
        //onSave({ name, description, discussions });
        onClose(); // Close modal after saving
    };

    const handleCancel = () => {
        setShowNewDiscussionInput(false)
        onClose(); // Close modal after saving
    };

    const handleAddDiscussion = () => {
        if (newDiscussion.trim()) {
            //setDiscussions([...discussions, newDiscussion]);
            setNewDiscussion('');
            setShowNewDiscussionInput(false); // Hide input after adding a discussion
        }
    };

    if (!isVisible) return null; // Don't render the modal if it's not visible


    return (
        <div className="task-modal-overlay">
            <div className="task-modal-content">
                <span className="close-button" onClick={onClose}>&times;</span>
                <div className="assigned-container">
                    <div className="client-input">
                        <label htmlFor="orderAmount">Client</label>
                        <select
                            id="client-select"
                            name="selectedClient"
                            value={formData.selectedClient}
                            onChange={handleChange}
                        >
                            <option value="">Select a client</option>
                            {clients && clients.map((client) => (
                                <option key={client.userId} value={client.userId}>{`${client.userFirstName} ${client.userLastName}`}</option>
                            ))}
                        </select>
                    </div>
                    <div className="client-input">
                        <label htmlFor="receivedAmount">Assigned To</label>
                        <select
                            id="advisor-select"
                            name="selectedAdvisor"
                            value={formData.selectedAdvisor}
                            onChange={handleChange}
                        >
                            <option value="">Select an advisor</option>
                            {advisors ? advisors.map((advisor) => (
                                <option key={advisor.employeeId} value={advisor.employeeId}>{advisor.employeeName}</option>
                            )) : []}
                        </select>
                    </div>
                </div>
                <input
                    className="task-input"
                    type="text"
                    placeholder="Task Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                />
                <textarea
                    className="task-textarea"
                    placeholder="Description"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                />
                <div className="date-conatiner">
                    <div className="date-field">
                        <label htmlFor="dueDate">Due Date</label>
                        <div className="date-input-container">
                            <FaRegCalendarAlt className="calendar-icon" />
                            <ReactDatePicker
                                selected={formData.dueDate}
                                onChange={handleDueDateChange}
                                className="date-picker-input"
                            />
                        </div>
                    </div>
                    <div className="date-field">
                        <label htmlFor="deadline">Deadline</label>
                        <div className="date-input-container">
                            <FaRegCalendarAlt className="calendar-icon" />
                            <ReactDatePicker
                                selected={formData.deadline}
                                onChange={handleDeadlineChange}
                                className="date-picker-input"
                            />
                        </div>
                    </div>
                </div>
                {showNewDiscussionInput && (
                    <textarea
                        className="task-textarea"
                        placeholder="Add new discussion"
                        name="newDiscussion"
                        value={formData.newDiscussion}
                        onChange={handleChange}
                        autoFocus
                    />
                )}
                {!showNewDiscussionInput && (
                    <div className="add-discussion-link" onClick={() => setShowNewDiscussionInput(true)}>
                        <FaPlus /> Add Discussion
                    </div>
                )}
                <div className="modal-actions">
                    <button className="save-btn" onClick={handleSave}>Save</button>
                    <button className="cancel-btn" onClick={handleCancel}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default TaskModal;