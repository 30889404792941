import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation
} from "react-router-dom";
import {
  ChakraBaseProvider,
  extendBaseTheme,
  theme as chakraTheme,
  Menu,
	MenuButton,
	MenuList,
	MenuItem,
	IconButton,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Progress,
  Box,
  ChakraProvider
} from '@chakra-ui/react'
import Navbar from "./Components/Navbar";
import Login from "./Components/Login";
import SideMenu from "./Components/SideMenu";
import Clients from "./Components/Clients";
import Profile from './Components/Profile';
import Documents from "./Components/Documents";
import Tasks from "./Components/Tasks";
import TaskDetailComponent from "./Components/TaskDetailComponent";
import ProtectedRoute from "./Components/ProtectedRoute";
import { AuthProvider } from "./Components/AuthContext";
import { DataProvider } from "./Components/DataContext";
import { ToastProvider } from "./Components/ToastContext";
import OrdersContainer from "./Components/OrdersContainer";
import ClientOrders from "./Components/ClientOrders";
import Admin from "./Components/Admin";

//IMPORTANT : put all the component used with chakra UI. Otherwise it doesn't work
const { Button } = chakraTheme.components

const theme = extendBaseTheme({
  components: {
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    IconButton,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
    Progress,
    Box
  },
})

function AppProviders({ children }) {
  return (
    <>
      <div className="appBody">
        <SideMenu />
        <Navbar />
        <div className="content">
          <DataProvider>
            <ToastProvider>
              {children}
            </ToastProvider>
          </DataProvider>
        </div>
      </div>
    </>
  );
}

function App() {
  return (
    <ChakraProvider>
        <AuthProvider>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route
              path="/*"
              element={
                <AppProviders>
                  <Routes>
                    <Route path="/clients" element={<ProtectedRoute><Clients /></ProtectedRoute>} />
                    <Route path="/clients/:clientName/documents" element={<Documents />} />
                    <Route path="/tasks/newtask" element={<TaskDetailComponent />} />
                    <Route path="/tasks" element={<ProtectedRoute><Tasks /></ProtectedRoute>} />
                    <Route path="/orders" element={<ProtectedRoute><OrdersContainer /></ProtectedRoute>} />
                    <Route path="/clients/client/orders" element={<ProtectedRoute><ClientOrders /></ProtectedRoute>} />
                    <Route path="/admin" element={<ProtectedRoute><Admin /></ProtectedRoute>} />
                    <Route path="/Profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />

                  </Routes>
                </AppProviders>
              }
            />
          </Routes>
        </AuthProvider>
    </ChakraProvider>
  );
}

// export default function App() {
//   return (
//     <Router>
//       <NestedApp />
//     </Router>
//   );
// }

export default App;
