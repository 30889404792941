import React, { useMemo, useState, useEffect, useCallback } from 'react';
import DocumentUploadModal from './DocumentUploadModal';
import { FaCloudUploadAlt } from '@react-icons/all-files/fa/FaCloudUploadAlt'
import { FaCloudDownloadAlt } from '@react-icons/all-files/fa/FaCloudDownloadAlt'
import { FaThumbsUp } from '@react-icons/all-files/fa/FaThumbsUp'
import { FaTrash } from '@react-icons/all-files/fa/FaTrash'
import { uploadFile, fileIcon, downloadFile, convertFileSize } from '../Utils/FileUtil';
import '../Styles/TaskAttachments.css'
import { useTable, useSortBy, useGlobalFilter } from 'react-table';
import { useToast } from './ToastContext';
import { format } from 'date-fns';
import ApiService from '../Services/ApiService';
import Loader from './Loader';
import { useDropzone } from 'react-dropzone';
import { IoIosCheckmarkCircle } from '@react-icons/all-files/io/IoIosCheckmarkCircle';
import { FaRegCircle } from '@react-icons/all-files/fa/FaRegCircle';
import { FaSitemap } from '@react-icons/all-files/fa/FaSitemap';
import * as Util from '../Utils/Util';
//import OrderFileMapModal from './OrderFileMapModal';
import OrderModal from './OrderModal';
import { useData } from './DataContext';

function globalFilter(rows, ids, query) {
    const lowercasedQuery = query.toLowerCase();

    return rows.filter(row => {
        return Object.values(row.original).some(value =>
            value !== null && value !== undefined && value.toString().toLowerCase().includes(lowercasedQuery)
        );
    });
}

const TaskAttachments = () => {
    const [isModalVisible, setModalVisible] = useState(false);
    const [allAttachedFiles, setAllAttachedFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const { toast, showToast } = useToast();
    const [droppableFiles, setDroppableFiles] = useState([]);
    const [checkedRows, setCheckedRows] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState('');
    const { orderList } = useData();

    const searchParams = new URLSearchParams(window.location.search);
    const taskId = searchParams.get('taskId');

    const fetchTaskFiles = async (id) => {
        const response = await ApiService.get("advisor/getFilesForTask?taskId=" + id);
        const mappedFiles = await response.json();
        return mappedFiles.data;
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const onFilesOrderMap = async (order) => {
        const checkedRowIds = checkedRows.map(obj => obj.fileId)
        let payload = {
            fileIds: checkedRowIds,
            orderId: order
        }
        try {
            const response = await ApiService.post("order/advisor/addFilesToOrder", payload);
            if (response.ok) {
                console.log("added to order")
                closeModal();
                setCheckedRows([]);
            }
        } catch (error) {
            console.log("error while approving")
        }
    };

    useEffect(() => {
        const fetchAttachedFiles = async (id) => {
            setLoading(true);
            try {
                fetchTaskFiles(id)
                    .then(mappedFiles => {
                        setAllAttachedFiles(mappedFiles);
                        setLoading(false);
                    })
                    .catch(error => {
                        console.error("Failed to load task attachments!", error);
                        setLoading(false);
                    });
            } catch (error) {
                setLoading(false);
                showToast('Failed to load task attchments!', 'failure');
            }
        };

        if (taskId) {
            fetchAttachedFiles(taskId);
        }
    }, [taskId]);

    const onDrop = useCallback((acceptedFiles, row = null) => {
        setModalVisible(true);
        setDroppableFiles(acceptedFiles);
    }, []);

    const IsItemInCheckedRows = (_rowID) => {
        return checkedRows.some(element =>
            element.id === _rowID
        );
    }

    const RenderActionBtn = () => {
        return checkedRows.length > 0;
    }

    const hasUnapprovedFiles = checkedRows.some(file => !file.isApproved);

    const onPressDownload = async () => {
        for (let _checkedRow of checkedRows) {
            const _id = _checkedRow.fileId;
            await downloadFile([_id], checkedRows);
        }
        setCheckedRows([]);
    }

    const onPressApprove = async () => {
        const checkedRowIds = checkedRows.filter(file => !file.isApproved).map(obj => obj.id)
        let payload = {
            mapIds: checkedRowIds,
            isApproved: true
        }
        try {
            const response = await ApiService.put("advisor/approveTaskFile", payload);
            if (response.ok) {
                console.log("approved")
                const updatedAttachedFiles = allAttachedFiles.map(file =>
                    checkedRowIds.includes(file.id) ? { ...file, isApproved: true } : file)
                setAllAttachedFiles(updatedAttachedFiles);
                setCheckedRows([]);
            }
        } catch (error) {
            console.log("error while approving")
        }
    }

    const columns = useMemo(
        () => [
            { // Checkbox column
                Header: '',
                accessor: 'select',
                disableSortBy: true,
                className: "sticky",
                width: "5%",
                maxWidth: "5%",
            },
            {
                Header: 'Name',
                accessor: 'fileName',
                width: "35%",
                maxWidth: "35%",
            },
            {
                Header: 'Upload Date',
                accessor: 'uploadTime',
                width: "15%",
                maxWidth: "15%",
                style: { textAlign: 'center' },
            },
            {
                Header: 'Uploaded By',
                accessor: 'uploadedBy',
                disableSortBy: true,
                width: "15%",
                maxWidth: "15%",
            },
            {
                Header: 'Notes',
                accessor: 'fileNote',
                disableSortBy: true,
                width: "25%",
                maxWidth: "25%",
            }
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setGlobalFilter,
        selectedFlatRows,
        state: { sortBy }
    } = useTable(
        {
            columns, data: allAttachedFiles, globalFilter, initialState: {
                sortBy: [
                    {
                        id: 'uploadDate',
                        desc: true
                    }
                ]
            }
        }, useGlobalFilter, useSortBy
    );

    const handleOpenModal = () => {
        console.log("opening model..");
        setModalVisible(true)
    };

    const handleSaveTask = async (files) => {
        for (let i = 0; i < files.length; i++) {
            const _uploadedFile = await uploadFile(files[i]);
            const attachFile = {
                "fileId": _uploadedFile.id,
                "taskId": parseInt(taskId)
            }
            const response = await ApiService.post("advisor/mapFileToTask", attachFile);
            if (response.ok) {
                setLoading(true);
                try {
                    fetchTaskFiles(taskId)
                        .then(mappedFiles => {
                            setAllAttachedFiles(mappedFiles);
                            setLoading(false);
                        })
                        .catch(error => {
                            console.error("Failed to load task attachments!", error);
                            setLoading(false);
                        });
                } catch (error) {
                    setLoading(false);
                    showToast('Failed to load task attchments!', 'failure');
                }
            }
        }
    };

    const handleCloseModal = () => setModalVisible(false);

    const CustomRow = ({ row, prepareRow }) => {
        const { getRootProps, getInputProps, isDragActive } = useDropzone({
            onDrop: (acceptedFiles) => onDrop(acceptedFiles, row),
            noClick: true,  // Prevents the dropzone from opening the file dialog when clicked
            noKeyboard: true // Prevents keyboard interactions
        });

        prepareRow(row);

        return (
            <tr {...row.getRowProps()} {...getRootProps()} className={`${isDragActive ? "border-2 border-orange-500" : ""}`}>
                {row.cells.map(cell => (
                    <td {...cell.getCellProps()} className={`break-all border-0 px-0 `}>
                        <CustomCell value={cell.value} column={cell.column} row={row} getInputProps={getInputProps} />
                    </td>
                ))}
            </tr>
        );
    };

    const CustomCell = ({ value, column, row, getInputProps }) => {
        if (column.id === 'select') {
            return <>
                <input {...getInputProps()} />
                <span className="group flex justify-center cursor-pointer"
                    onClick={() => {
                        if (IsItemInCheckedRows(row.original.id)) {
                            let _checkedRows = checkedRows.filter(element => !(element.id === row.original.id));
                            setCheckedRows(_checkedRows);
                        }
                        else {
                            setCheckedRows([...checkedRows, row.original]);
                        }
                    }}>
                    {IsItemInCheckedRows(row.original.id) ? <IoIosCheckmarkCircle className="text-orange-500 h-6 w-6" style={{ strokeWidth: '2' }} />
                        : <FaRegCircle className="text-gray-300 group-hover:text-orange-500 h-5 w-5" style={{ strokeWidth: '2' }} />}
                </span>
            </>
        }
        else {
            if (column.id === 'uploadTime') {
                const formattedDate = new Date(row.original.uploadTime).toLocaleDateString();
                return <span className="flex justify-center items-center space-x-2 select-none cursor-pointer">
                    <span className="text-sm">{Util.formatDate(formattedDate)}</span>
                </span>
            }
            else if (column.id === 'fileName') {
                return <span className="flex items-center space-x-2 select-none cursor-pointer">
                    {fileIcon(row.original.fileType)}
                    {/*value.endsWith('.pdf') ? <FiFileText /> : <FcFolder className="h-5 w-5"/>*/}
                    <span>{value}</span>
                    {row.original.isApproved && <span className="approved-text"> (approved)</span>}
                </span>
            }
            else if (column.id === 'uploadedBy') {
                return <span className="flex justify-center items-center space-x-2 select-none cursor-pointer">
                    <span className="text-sm">{row.original.uploadedBy}</span>
                </span>
            }
            else if (column.id === 'fileNote') {
                return <span className="flex justify-center items-center space-x-2 select-none cursor-pointer">
                    <span className="text-sm">{row.original.fileNote}</span>
                </span>
            }
            return <span>{value}</span>;
        }

    };

    return (
        <>
            <div className="flex justify-end space-x-1 mb-2.5 w-full">

                <div className="flex justify-end space-x-1">
                    {RenderActionBtn() &&
                        <>
                            {!hasUnapprovedFiles &&
                                <button className="flex items-center px-2.5 py-2.5 bg-orange-500 text-white text-sm leading-none tracking-wide rounded cursor-pointer hover:bg-orange-700 uploadBbtn" onClick={openModal}>
                                    <FaSitemap className="mr-1.5" /> Add To Order
                                </button>
                            }
                            <button className="flex items-center px-2.5 py-2.5 bg-orange-500 text-white text-sm leading-none tracking-wide rounded cursor-pointer hover:bg-orange-700 uploadBbtn" onClick={onPressDownload}>
                                <FaCloudDownloadAlt className="mr-1.5" /> Download
                            </button>
                            <button className="flex items-center px-2.5 py-2.5 bg-orange-500 text-white text-sm leading-none tracking-wide rounded cursor-pointer hover:bg-orange-700 uploadBbtn" onClick={onPressApprove}>
                                <FaThumbsUp className="mr-1.5" /> Approve
                            </button>
                        </>}
                    {isModalOpen && (
                        <OrderModal
                            mode='fileMap'
                            orders={orderList}
                            onClose={closeModal}
                            onFilesOrderMap={onFilesOrderMap}
                        />
                    )}
                </div>

                <button className="flex items-center px-2.5 py-2.5 bg-orange-500 text-white text-sm leading-none tracking-wide rounded cursor-pointer hover:bg-orange-700 uploadBbtn" onClick={handleOpenModal}>
                    <FaCloudUploadAlt className="mr-1.5" /> Upload
                </button>
            </div>
            <DocumentUploadModal
                isVisible={isModalVisible}
                onClose={handleCloseModal}
                onSave={handleSaveTask}
                droppableFiles={droppableFiles}
            />
            {loading ?
                <Loader></Loader>
                :
                (allAttachedFiles.length === 0) ?
                    <div class="no-data">No data available</div>
                    :
                    <div>
                        <table {...getTableProps()} className="w-full">
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{ width: column.width, maxWidth: column.maxWidth }} className={`sticky top-0 bg-white border-0 text-left p-2 select-none cursor-pointer ${column.isSorted ? column.isSortedDesc ? 'sort-desc' : 'sort-asc' : ''}`}>
                                                {column.render('Header')}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {rows.map(row =>
                                    <CustomRow key={row.original.key} row={row} prepareRow={prepareRow} />
                                )}
                            </tbody>
                        </table>
                    </div>
            }
        </>
    );
};

export default TaskAttachments;