import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import ApiService from '../Services/ApiService';
import { useData } from './DataContext';
import { MdModeEdit } from '@react-icons/all-files/md/MdModeEdit';
import { GiHamburgerMenu } from "@react-icons/all-files/gi/GiHamburgerMenu";
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
    IconButton
} from '@chakra-ui/react';
import Toast from './ToastComponent';
import { useToast } from './ToastContext';
import { isDigitsOnly } from '../Utils/Util';
import { FaPlusCircle } from "@react-icons/all-files/fa/FaPlusCircle.js";


const SkillEditSubModal = ({item, onclose, refreshAdvisorSkills}) =>{
	const [currSkillName, setCurrSkillName] = useState(item.skillName);
	const [currSkillPrice, setCurrSkillPrice] = useState(item.skillPrice);
	const [isUpdating, setIsUpdating] = useState(false);
    const { toast, showToast } = useToast();

	if (item === undefined || item === null ) return;

	const handleOnClose = () =>{
		setCurrSkillName('');
		setCurrSkillPrice('');
        setIsUpdating(false);
		onclose();
	}

	const handleChangeName = (e) =>{
		setCurrSkillName(e.target.value);
	}

    const handleChangePrice = (e) =>{
		setCurrSkillPrice(e.target.value);
	}

	const handleOnSave = async() =>{
		if (isUpdating) return;

		setIsUpdating(true);

        if(currSkillName.trim().length === 0){
            showToast("Skill's name can not be empty", 'failure');
			setIsUpdating(false);
            return;
        }
        if(currSkillPrice <= 0){
            showToast("Skill's price can not be 0 or negative", 'failure');
			setIsUpdating(false);
            return;
        }
        if(!isDigitsOnly(currSkillPrice)){
            showToast("Please enter numbers only for price", 'failure');
			setIsUpdating(false);
            return;
        }

		try{
			const payloadSkill = {};

            if(item.skillName !== currSkillName.trim())
                payloadSkill.skillName = currSkillName.trim();

            if(item.skillPrice !== currSkillPrice)
                payloadSkill.skillCharge = currSkillPrice;

            if(Object.keys(payloadSkill).length === 0)
                handleOnClose();

            payloadSkill.skillId = item.id;
            const payloadSkills = [];
            payloadSkills.push(payloadSkill);
            const payload = {
                skills : payloadSkills
            };

			const response = await ApiService.put('advisor/updateSkills', payload);

			if (response.ok) {
				refreshAdvisorSkills();
				handleOnClose();
			}
			setIsUpdating(false);
		}
		catch(error){
			//error
			setIsUpdating(false);
		}

	}

	return(
		<div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
            <Toast message={toast.message} type={toast.type} />
			<div className="bg-white p-6 rounded-lg shadow-md flex flex-col space-y-4 items-center w-1/3">
			<div className="flex flex-col items-center space-y-2">
				<h2 className="text-lg font-semibold">{'Edit Skill'}</h2>
			</div>
            <div className='flex flex-row justify-start w-full items-center'>
                <p className='mr-4 text-xl'>Name</p>
                <div className="flex items-center mt-2 mb-2 w-full border border-gray-300 rounded-sm focus-within:border-orange-500 focus-within:ring focus-within:ring-orange-200 focus-within:ring-opacity-50">
                <input
					type="text"
					value={currSkillName}
					onChange={handleChangeName}
					autoFocus
					className="border rounded px-2 py-1 w-full"
				/>
			</div>
            </div>
            <div className='flex flex-row justify-start w-full items-center'>
            <p className='mr-4 text-xl'>Price</p>
            <div className="flex items-center mt-2 mb-2 w-full border border-gray-300 rounded-sm focus-within:border-orange-500 focus-within:ring focus-within:ring-orange-200 focus-within:ring-opacity-50">
                <input
					type="number"
					value={currSkillPrice}
					onChange={handleChangePrice}
					className="border rounded px-2 py-1 w-full"
				/>
            </div>
            </div>

			<div className="flex justify-end space-x-2 w-full">
                    <button className="px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-700" onClick={handleOnSave}>{isUpdating ? 'Saving...' : 'Save'}</button>
                    <button className="px-4 py-2 bg-orange-300 text-white rounded hover:bg-orange-400" onClick={handleOnClose}>Cancel</button>
			</div>
			</div>
		</div>
	
	);
}

const SkillAddSubModal = ({onclose, refreshAdvisorSkills}) =>{
	const [currSkillName, setCurrSkillName] = useState('');
	const [currSkillPrice, setCurrSkillPrice] = useState(0);
	const [isUpdating, setIsUpdating] = useState(false);
    const { toast, showToast } = useToast();

	const handleOnClose = () =>{
		setCurrSkillName('');
		setCurrSkillPrice('');
        setIsUpdating(false);
		onclose();
	}

	const handleChangeName = (e) =>{
		setCurrSkillName(e.target.value);
	}

    const handleChangePrice = (e) =>{
		setCurrSkillPrice(e.target.value);
	}

	const handleOnSave = async() =>{
		if (isUpdating) return;

		setIsUpdating(true);

        if(currSkillName.trim().length === 0){
            showToast("Skill's name can not be empty", 'failure');
			setIsUpdating(false);
            return;
        }
        if(currSkillPrice <= 0){
            showToast("Skill's price can not be 0 or negative", 'failure');
			setIsUpdating(false);
            return;
        }
        if(!isDigitsOnly(currSkillPrice)){
            showToast("Please enter numbers only for price", 'failure');
			setIsUpdating(false);
            return;
        }

		try{
			const payloadSkill = {};
            payloadSkill.skillName = currSkillName.trim();
            payloadSkill.skillCharge = currSkillPrice;
            const payloadSkills = [];
            payloadSkills.push(payloadSkill);
            const payload = {
                skills : payloadSkills
            };

			const response = await ApiService.post('advisor/skillCharges', payload);

			if (response.ok) {
				refreshAdvisorSkills();
				handleOnClose();
			}
			setIsUpdating(false);
		}
		catch(error){
			//error
			setIsUpdating(false);
		}

	}

	return(
		<div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
            <Toast message={toast.message} type={toast.type} />
			<div className="bg-white p-6 rounded-lg shadow-md flex flex-col space-y-4 items-center w-1/3">
			<div className="flex flex-col items-center space-y-2">
				<h2 className="text-lg font-semibold">{'Add New Skill'}</h2>
			</div>
            <div className='flex flex-row justify-start w-full items-center'>
                <p className='mr-4 text-xl'>Name</p>
                <div className="flex items-center mt-2 mb-2 w-full border border-gray-300 rounded-sm focus-within:border-orange-500 focus-within:ring focus-within:ring-orange-200 focus-within:ring-opacity-50">
                <input
					type="text"
					value={currSkillName}
					onChange={handleChangeName}
					autoFocus
					className="border rounded px-2 py-1 w-full"
				/>
			</div>
            </div>
            <div className='flex flex-row justify-start w-full items-center'>
            <p className='mr-4 text-xl'>Price</p>
            <div className="flex items-center mt-2 mb-2 w-full border border-gray-300 rounded-sm focus-within:border-orange-500 focus-within:ring focus-within:ring-orange-200 focus-within:ring-opacity-50">
                <input
					type="number"
					value={currSkillPrice}
					onChange={handleChangePrice}
					className="border rounded px-2 py-1 w-full"
				/>
            </div>
            </div>

			<div className="flex justify-end space-x-2 w-full">
                    <button className="px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-700" onClick={handleOnSave}>{isUpdating ? 'Saving...' : 'Save'}</button>
                    <button className="px-4 py-2 bg-orange-300 text-white rounded hover:bg-orange-400" onClick={handleOnClose}>Cancel</button>
			</div>
			</div>
		</div>
	
	);
}

const SkillDeleteSubModal = ({item, onclose, refreshAdvisorSkills}) =>{
	const [isUpdating, setIsUpdating] = useState(false);
    const { toast, showToast } = useToast();

    if (item === undefined || item === null ) return;

	const handleOnClose = () =>{
        setIsUpdating(false);
		onclose();
	}

	const handleOnDelete = async() =>{
		if (isUpdating) return;

		setIsUpdating(true);

		try{
			const payloadSkill = {};
            payloadSkill.skillId = item.id;
            const payloadSkills = [];
            payloadSkills.push(payloadSkill);
            const payload = {
                skills : payloadSkills
            };

			const response = await ApiService.put('advisor/removeSkills', payload);

			if (response.ok) {
				refreshAdvisorSkills();
				handleOnClose();
			}
			setIsUpdating(false);
		}
		catch(error){
			//error
			setIsUpdating(false);
		}

	}

	return(
		<div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
            <Toast message={toast.message} type={toast.type} />
			<div className="bg-white p-6 rounded-lg shadow-md flex flex-col space-y-4 items-center w-1/3">
			<div className="flex flex-col items-center space-y-2">
				<h2 className="text-lg font-semibold">{'Do you want to delete this skill?'}</h2>
			</div>
            <div className='flex flex-row justify-start w-full items-center'>
                <p className='mr-4 text-xl'>Name</p>
                <p>{item.skillName}</p>
            </div>
            <div className='flex flex-row justify-start w-full items-center'>
            <p className='mr-4 text-xl'>Price</p>
            <p>{item.skillPrice + '/-'}</p>
            </div>

			<div className="flex justify-end space-x-2 w-full">
                    <button className="px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-700" onClick={handleOnDelete}>{isUpdating ? 'Deleting...' : 'Delete'}</button>
                    <button className="px-4 py-2 bg-orange-300 text-white rounded hover:bg-orange-400" onClick={handleOnClose}>Cancel</button>
			</div>
			</div>
		</div>
	
	);
}

const Profile = () =>{
    const { isAdmin } = useAuth();
    const { skillList, refreshAdvisorSkills } = useData();
    const [ editingSkill, setEditingSkill ] = useState(null);
    const [isSkillEditModalVisible, setIsSkillEditModalVisible] = useState(false);
    const [isSkillAddModalVisible, setIsSkillAddModalVisible] = useState(false);
    const [isSkillDeleteodalVisible, setIsSkillDeleteModalVisible] = useState(false);
    const [selectedSkill, setSelectedSkill] = useState(null);

    if(isAdmin === false) return;

    const SkillItem = ({_skillItem}) =>{

        const handleOnClickEdit = () =>{
            setSelectedSkill(_skillItem);
            setIsSkillEditModalVisible(true);
        }

        const handleOnClickDelete = () =>{
            setSelectedSkill(_skillItem);
            setIsSkillDeleteModalVisible(true);
        }

        return(
            <div className='flex flex-row items-center'>
                <p className='mr-4 font-medium'>{_skillItem.skillName}</p>
                <p>{_skillItem.skillPrice + '/-'}</p>
                <div>
                    <Menu>
                        <MenuButton
                            as={IconButton}
                            aria-label='Options'
                            icon={<MdModeEdit className="h-4 w-4" color='#fe5f00'/>}
                            size='sm'
                            bg='white'
                        />
                        <MenuList>
                            <MenuItem onClick={handleOnClickEdit}>
                                Edit
                            </MenuItem>
                            <MenuItem onClick={handleOnClickDelete}>
                                Delete
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </div>

            </div>
        );
    }

    return(
        <div className="flex flex-row p-4  justify-start w-1/2 ">
            <div className='flex flex-col w-full'>
                <div className='border-2 border-orange-500 rounded-md p-2 w-3/4 shadow-md'>
                    <p className=" text-lg font-bold mb-4 text-center">Skills</p>
                    <div className="w-full flex justify-end">
                        <div className='border-2 border-orange-500 rounded-md w-fit py-1 px-2 flex flex-row items-center cursor-pointer shadow-lg'
                            onClick={() => setIsSkillAddModalVisible(true)}>
                            <FaPlusCircle className='h-4 w-4 mr-2'/>
                            <p className='pr-2'>Add New</p>
                        </div>
                    </div>
                    <ul className="space-y-2">
                        {skillList.map(skill => (
                        <li key={skill.id} className="flex items-center space-x-2">
                            <SkillItem _skillItem={skill}/>
                        </li>
                        ))}
                    </ul>
                    {isSkillEditModalVisible &&
                    <SkillEditSubModal
                        item={selectedSkill}
                        onclose={() => {setIsSkillEditModalVisible(false); setSelectedSkill(null);}}
                        refreshAdvisorSkills={refreshAdvisorSkills}
                    />}
                    {isSkillAddModalVisible &&
                    <SkillAddSubModal
                        onclose={() => {setIsSkillAddModalVisible(false);}}
                        refreshAdvisorSkills={refreshAdvisorSkills}
                    />}
                    {isSkillDeleteodalVisible &&
                    <SkillDeleteSubModal
                    item={selectedSkill}
                        onclose={() => {setIsSkillDeleteModalVisible(false); setSelectedSkill(null);}}
                        refreshAdvisorSkills={refreshAdvisorSkills}
                    />}
                </div>
                
            </div>
        </div>
    );
}

export default Profile;